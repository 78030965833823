import {
  InputGroup, TextInput, getErrorMessages, ErrorMessages,
} from '@selectra-it/selectra-ui'

import useEstimationWizardForm from '@hooks/estimation/useEstimationWizardForm'
import useGetInputStatus from '@hooks/useGetInputStatus'
import NavigationButtons from '@components/form/NavigationButtons'

import {
  WizardStepProps,
} from '../EstimationFormWizard'

export interface HouseRoomsInformationForm {
  housing_rooms: number
}

const HouseRoomsInformation = ({
  next,
}: WizardStepProps) => {
  const {
    form: {
      register, formState: {
        errors,
      },
    },
    onSubmitHandler, onBackHandler,
  } = useEstimationWizardForm({
    next,
  })

  const status = useGetInputStatus(errors?.housing_rooms?.type)
  const inputErrors = getErrorMessages({
    ...ErrorMessages,
    required: 'J’ai besoin du nombre de pièces de votre logement afin d’établir vos devis.',
  }, errors?.housing_rooms?.type)

  return (
    <form onSubmit={onSubmitHandler()} className='flex w-full grow flex-col justify-between space-y-6'>
      <InputGroup
        errors={inputErrors}
      >
        <TextInput label='Nombre de pièces de vie'
          status={status}
          {...register('housing_rooms', {
            required: true,
          })}
          type="number"
        />

      </InputGroup>
      <NavigationButtons onBackHandler={onBackHandler} />
    </form>
  )
}

export default HouseRoomsInformation
