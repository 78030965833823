import Icon from '@components/Icon'

interface IconInformation {
  name: string,
  fill: string,
}

interface Item {
  icon: IconInformation,
  title: string,
  items: string[]
}

interface CollasibleMenuContentProps {
  item: Item
}

const CollasibleMenuContent = ({
  item,
}: CollasibleMenuContentProps) => {
  return (
    <>
      <h3 className="mt-6 align-middle text-base text-neutral-400">
        <span className='text-sm font-bold text-neutral-400'>{item.title}</span>
      </h3>
      <ul className='list-inside'>
        {item.items.map(listItem => <li key={listItem} className='my-2 flex items-center gap-2 text-sm text-neutral-400'>
          <div className='flex items-center'>
            <Icon name={item.icon.name} size={16}
              className={`block ${item.icon.fill}`}
            />
          </div>
          <span className='first-letter:capitalize'>{listItem}</span>
        </li>)}
      </ul>
    </>
  )
}

export default CollasibleMenuContent
