import {
  forwardRef,
} from 'react'
import {
  ChangeHandler, FieldError,
} from 'react-hook-form'

import Icon from '@components/Icon'

interface CheckboxProps {
  onChange: ChangeHandler,
  onBlur: ChangeHandler,
  id: string,
  name: string,
  label: string,
  error?: FieldError,
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const {
    onChange,
    onBlur,
    id,
    name,
    label,
    error,
  } = props

  return (
    <div className="flex">
      <div>
        <input
          ref={ref}
          id={id}
          name={name}
          type="checkbox"
          onChange={onChange}
          onBlur={onBlur}
          className='peer sr-only flex-none rounded-lg border-gray-300 bg-gray-100 accent-primary-400 shadow focus:ring-2'
        />
        <label htmlFor={name} className={`left-0 top-0 flex size-8 items-center justify-center rounded-lg bg-white peer-checked:bg-primary-400 peer-focus:ring-2 peer-focus:ring-primary-400 ${error ? 'ring-2 ring-danger-400' : ''}`}>
          <Icon name='check' size={16}
            className='fill-white'
          />
        </label>
      </div>
      <label htmlFor={name} className="ml-2 text-left text-sm font-medium text-neutral-450">{label}</label>
    </div>
  )
})

Checkbox.displayName = 'Checkbox'

export default Checkbox
