import {
  useRef,
} from 'react'

import BooleanField from '@components/form/estimation/BooleanField'
import useEstimationWizardForm from '@hooks/estimation/useEstimationWizardForm'
import NavigationButtons from '@components/form/NavigationButtons'

import {
  WizardStepProps,
} from '../EstimationFormWizard'

const AccomodationCoveredInformation = ({
  next,
}: WizardStepProps) => {
  const formRef = useRef<HTMLFormElement>(null)

  const {
    form: {
      register, formState: {
        errors,
      },
    },
    onSubmitHandler, onBackHandler,
  } = useEstimationWizardForm({
    mode: 'onChange',
    revalidateMode: 'onChange',
    next,
  })

  const onAnimationFinishedHandler = () => {
    formRef?.current?.dispatchEvent(new Event('submit', {
      cancelable: true,
      bubbles: true,
    }))
  }

  return (
    <form ref={formRef} onSubmit={onSubmitHandler()}
      className='flex w-full grow flex-col justify-between space-y-6'
    >
      <BooleanField
        onAnimationFinished={onAnimationFinishedHandler}
        register={register}
        name='accommodationCovered'
        errors={errors.accommodationCovered}
      />

      <NavigationButtons onBackHandler={onBackHandler} />
    </form>
  )
}

export default AccomodationCoveredInformation
