import {
  Datepicker,
  InputGroup,
  getErrorMessages, ErrorMessages,
  RadioStackedContainer,
  RadioStacked,
} from '@selectra-it/selectra-ui'
import {
  useEffect,
  useRef,
} from 'react'
import {
  useTranslation,
} from 'react-i18next'

import dayjs from '@root/plugins/dayjs'
import useEstimationWizardForm from '@hooks/estimation/useEstimationWizardForm'
import useGetInputStatus from '@hooks/useGetInputStatus'
import NavigationButtons from '@components/form/NavigationButtons'
import useGetRadioStatus from '@hooks/useGetRadioStatus'
import useSubmitFormOnChange from '@hooks/useFormSubmitOnChange'

import {
  WizardStepProps,
} from '../EstimationFormWizard'

const ContractStartDateInformation = ({
  next,
}: WizardStepProps) => {
  const formRef = useRef<HTMLFormElement>(null)
  const {
    form: {
      register, formState: {
        errors,
        isDirty,
      },
      watch,
      setValue,
      trigger,
    },
    onSubmitHandler, onBackHandler,
  } = useEstimationWizardForm({
    next,
    revalidateMode: 'onChange',
  })

  const {
    t,
  } = useTranslation(['wizard'])

  const now = dayjs() // today's date
  const tomorrow = now.add(1, 'day') // tomorrow's date
  const todayValue = now.format('DD/MM/YYYY') // formatted as DD/MM/YYYY
  const tomorrowValue = tomorrow.format('DD/MM/YYYY')
  const todayFormatted = now.format('dddd D MMMM YYYY') // formatted as "day dayNumber month year"
  const tomorrowFormatted = tomorrow.format('dddd D MMMM YYYY')

  const inOneMonth = now.add(1, 'month').toDate()

  const startDate = watch('startDate')
  const startDateRadio = watch('startDateRadio')

  const items = [{
    id: 'today', label: [t('wizard:wizard.steps.startDate.today'), todayFormatted], value: todayValue,
  }, {
    id: 'later', label: [t('wizard:wizard.steps.startDate.tomorrow'), tomorrowFormatted], value: tomorrowValue,
  }]

  useEffect(() => {
    if (startDate && startDate?.length > 0) {
      setValue('startDateRadio', '')
      trigger('startDateRadio')
    }
  }, [startDate, setValue, trigger])

  useEffect(() => {
    if (startDateRadio && startDateRadio.length > 0) {
      setValue('startDate', '')
      trigger('startDate')
    }
  }, [startDateRadio, setValue, trigger])

  const inputErrors = getErrorMessages({
    ...ErrorMessages,
    required: 'Veuillez saisir votre date de debut de contrat dans champ ci-dessus afin de poursuivre. Ex : 31/01/2024',
  }, errors?.startDate?.type)

  useSubmitFormOnChange(isDirty, startDateRadio, formRef)
  const status = useGetInputStatus(errors?.startDate?.type)
  const startDateRadioStatus = useGetRadioStatus(errors?.startDateRadio?.type)

  return (
    <form
      onSubmit={onSubmitHandler()}
      className='mb-8 flex w-full grow flex-col justify-between space-y-6 md:mb-0'
      ref={formRef}
    >
      <InputGroup
        errors={inputErrors}
        description={'Ex : 31/01/2024'}
      >
        <RadioStackedContainer>
          {items.map(({
            id,
            label,
            value,
          }) => (
            <RadioStacked
              key={id}
              id={id}
              item={{
                label,
                value,
              }}
              status={startDateRadioStatus}
              {...register('startDateRadio', {
                validate: {
                  required: (value, {
                    startDate,
                  }) => {
                    if (startDate !== null && startDate?.length > 0) {
                      return true
                    }

                    if (value !== null && value?.length > 0) {
                      return true
                    }

                    return false
                  },
                },
              })}
              dataCy={value}
            />
          ))}
        </RadioStackedContainer>

        <div className='py-2 text-center text-neutral-400'>
          ou
        </div>

        <Datepicker
          label={t('wizard:wizard.steps.startDate.label')}
          maxDate={inOneMonth}
          status={status}
          minDate={now.add(2, 'day').toDate()}
          {...register('startDate', {
            validate: {
              required: (value, {
                startDateRadio,
              }) => {
                if (startDateRadio !== null && startDateRadio?.length > 0) {
                  return true
                }

                if (value !== null && value?.length > 0) {
                  return true
                }

                return false
              },
            },
          })}
          dataCy="insurance-date"
        />

      </InputGroup>

      <NavigationButtons onBackHandler={onBackHandler} />
    </form>
  )
}

export default ContractStartDateInformation
