import {
  useDispatch,
  useSelector,
} from 'react-redux'

import Modal from '@components/Modal/Modal'
import {
  Size,
} from '@components/Modal/Size'
import CollapsibleMenu from '@components/ui/CountryFlag/CollapsibleMenu'
import Icon from '@components/Icon'
import {
  GUARANTEE_MODAL,
  isModalOpen,
  toggleGuaranteesModal,
} from '@root/services/modalSlice'
import {
  RootState,
} from '@root/store/Reducer'

import CollasibleMenuTrigger from './CollasibleMenu/CollasibleMenuTrigger'
import CollasibleMenuContent from './CollasibleMenu/CollasibleMenuContent'

const content = [
  {
    title: 'Dégât des eaux',
    icon: 'home-assistance-water',
    items: [
      {
        icon: {
          name: 'circle',
          fill: '',
        },
        title: 'La garantie s’active dans les cas de :',
        items: [
          'fuite',
          'rupture de canalisation',
          'débordement (d\'une baignoire ou d\'un lave-linge par exemple)'
        ],
      },
      {
        icon: {
          fill: 'fill-success-400',
          name: 'check',
        },
        title: 'Vos biens couverts :',
        items: [
          'murs',
          'toiture',
          'revêtement du sol',
          'meubles et appareils électroménagers endommagés'
        ],
      },
      {
        icon: {
          fill: 'fill-danger-400',
          name: 'cross',
        },
        title: 'Exclusion notable :',
        items: [
          'bien à l’origine du dommage (le lave-linge par exemple)'
        ],
      }
    ],
  },
  {
    title: 'Incendie',
    icon: 'home-assistance-fire',
    items: [
      {
        title: 'La garantie s’active dans les cas de :',
        icon: {
          name: 'circle',
          fill: '',
        },
        items: [
          'foudre',
          'incendie',
          'explosion',
          'dégâts associés à l’extinction du feu et à l’intervention des pompiers'
        ],
      },
      {
        title: 'Vos biens couverts :',
        icon: {
          fill: 'fill-success-400',
          name: 'check',
        },
        items: [
          'meubles',
          'électroménagers',
          'vêtements',
          'etc.'
        ],
      },
      {
        icon: {
          fill: 'fill-danger-400',
          name: 'cross',
        },
        title: 'Exclusion notable :',
        items: [
          'Les biens de valeur (bijoux, œuvres d’art, ordinateurs…) sont couverts à un niveau que vous définissez au moment de la souscription avec l’assureur.'
        ],
      }
    ],
  },
  {
    title: 'Responsabilité civile',
    icon: 'home-assistance-civil-responsibility',
    items: [
      {
        title: 'La garantie s’active pour les accidents :',
        icon: {
          name: 'circle',
          fill: '',
        },
        items: [
          'causés par votre faute',
          'commis par les personnes qui vivent sous votre toit',
          'causés par vos animaux',
          'causés par les objets que vous possédez',
          'causés par votre logement'
        ],
      },
      {
        title: 'Exclusions notables :',
        icon: {
          fill: 'fill-danger-400',
          name: 'cross',
        },
        items: [
          'dommages causés intentionnellement',
          'dommages que vous causez à vos proches',
          'dommages causés par votre chien s’il est considéré comme chien dangereux'
        ],
      }
    ],
  },
  {
    title: 'Catastrophe naturelle et tempête',
    icon: 'home-assistance-natural-disaster',
    items: [
      {
        title: 'La garantie s’active dans les cas de :',
        icon: {
          name: 'circle',
          fill: '',
        },
        items: [
          'vent supérieur à 100km/h',
          'grêle',
          'poids de la neige sur une vitre de voiture',
          'le gel',
          'inondation et coulée de boue',
          'glissement de terrain'
        ],
      },
      {
        title: 'Vos biens couverts :',
        icon: {
          fill: 'fill-success-400',
          name: 'check',
        },
        items: [
          'biens immobiliers (les murs, le toit)',
          'biens mobiliers (meubles, électroménager, vêtements)',
          'véhicules'
        ],
      },
      {
        title: 'Exclusions notables :',
        icon: {
          fill: 'fill-danger-400',
          name: 'cross',
        },
        items: [
          'vitres du logement (seule la garantie “bris de glace” permet de couvrir les dommages causés aux vitres)',
          'dommage causés à des bâtiments non entièrement clos ou couverts'
        ],
      }
    ],
  },
  {
    title: 'Défense Pénale et Recours',
    icon: 'home-assistance-legal-support',
    items: [
      {
        title: 'La garantie s’active dans les cas de :',
        icon: {
          name: 'circle',
          fill: '',
        },
        items: [
          'litige liés à un sinistre ou un événement couvert par votre contrat d’assurance habitation'
        ],
      },
      {
        title: 'Sont remboursés :',
        icon: {
          fill: 'fill-success-400',
          name: 'check',
        },
        items: [
          'frais de justice',
          'honoraire d’avocat',
          'expertise nécessaire à votre défense'
        ],
      },
      {
        title: 'Exclusion notable : ',
        icon: {
          fill: 'fill-danger-400',
          name: 'cross',
        },
        items: [
          'tout litige lié à un sinistre ou un événement non couvert par votre contrat d’assurance habitation'
        ],
      }
    ],
  },
  {
    title: 'Attentat',
    icon: 'home-assistance-terrorism',
    items: [
      {
        title: 'La garantie s’active dans les cas de :',
        icon: {
          name: 'circle',
          fill: '',
        },
        items: [
          'attentat',
          'acte de terrorisme',
          'émeute',
          'manifestation populaire'
        ],
      },
      {
        title: 'Vos biens couverts :',
        icon: {
          fill: 'fill-success-400',
          name: 'check',
        },
        items: [
          'la réparation des dommages matériels (mobilier et immobilier)',
          'la réparation des dommages corporels au travers du Fonds de Garantie des victimes des actes de Terrorisme et d’autres Infractions (FGTI)'
        ],
      },
      {
        title: 'Exclusion notable :',
        icon: {
          fill: 'fill-danger-400',
          name: 'cross',
        },
        items: [
          'Tous les événements qui ne seront pas reconnus par le gouvernement comme attentat ou acte de terrorisme.'
        ],
      }
    ],
  }
]

const OfferMessageMinimumLegalModal = () => {
  const isOpen = useSelector((state: RootState) => isModalOpen(state, GUARANTEE_MODAL))

  const dispatch = useDispatch()

  return (
    <Modal
      size={Size.LG}
      isOpen={isOpen}
      onRequestClose={() => { dispatch(toggleGuaranteesModal()) }}
    >
      <div className='flex max-h-[80vh] flex-col gap-9'>
        <div className='flex justify-between'>
          <div className='flex items-center gap-1 text-base font-bold text-neutral-500'>
            <Icon name='help' size={24}
              className='inline-block fill-primary-300'
            />
            Garanties obligatoires
          </div>
        </div>
        <div className='flex flex-col gap-4 overflow-y-scroll'>
          <p className='text-base text-neutral-400'>
            Certaines garanties sont obligatoires et communes à chaque contrat d’assurance habitation. Elles sont donc automatiquement incluses dans les contrats, quelle que soit la compagnie choisie.
          </p>
          <ul className='pr-3'>
            {content.map(item =>
              <CollapsibleMenu
                key={item.title}
                trigger={<CollasibleMenuTrigger title={item.title} icon={item.icon} />}
              >
                {item.items.map(item => <CollasibleMenuContent item={item} key={item.title}/>)}
              </CollapsibleMenu>
            )}
          </ul>
        </div>
      </div>
    </Modal>
  )
}

export default OfferMessageMinimumLegalModal
