import Icon from '@components/Icon'

interface CollasibleMenuTriggerProps {
  title: string,
  icon: string,
}

const CollasibleMenuTrigger = ({
  title,
  icon,
}: CollasibleMenuTriggerProps) => {
  return (
    <>
      <input type='checkbox' className='peer absolute inset-x-0 top-0 z-20 size-full cursor-pointer opacity-0' />
      <div
        className=" flex items-center gap-2"
      >
        <Icon name={icon} className='inline-block  fill-success-300 align-middle'
          size={24}
        />
        <span className='text-base text-neutral-400'>{title}</span>
      </div>

      <Icon name='expand more' className='absolute right-3 top-[16px] fill-neutral-400 transition-all duration-500 peer-checked:rotate-180'
        size={24}
      />
    </>
  )
}

export default CollasibleMenuTrigger
