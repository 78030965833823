export default {
  subscription: {
    success: {
      callback: 'Vos coordonnées ont été transmises avec succès à {{insurerName}} !',
      callback_selectra: 'Nous avons enregistré votre demande',
      description: 'Vous serez rappelé dans les plus brefs délais au {{phoneNumber}}',
    },
  },
  mer: {
    request_callback: {
      title: 'L\'assureur vous rappelle',
      description: 'Laissez vos coordonnées et l\'assureur vous rappellera pour finaliser votre souscription.',
    },
    request_callback_selectra: {
      title: 'Rappel gratuit d\'un expert',
      description: 'Laissez vos coordonnées et nous vous rappellerons pour finaliser votre souscription.',
    },
  },
}
