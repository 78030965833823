import {
  apiService,
} from '@services/apiService'

interface HousingTypesResponseItem {
  key: string,
  label: string
}

interface HousingTypesResponse {
  items: HousingTypesResponseItem[]
}

interface HousingType {
  label: string,
  icon: string,
  value: string,
}

const residenceTypesApi = apiService.injectEndpoints({
  endpoints: builder => ({
    getHousingTypes: builder.query<HousingType[], void>({
      query: () => 'api/home/housing-types',
      transformResponse: (response: HousingTypesResponse) => {
        return response.items.map(item => ({
          label: item.label,
          icon: item.key,
          value: item.key,
        }))
      },
      keepUnusedDataFor: 60 * 10,
    }),
  }),
})

export const {
  useGetHousingTypesQuery,
} = residenceTypesApi
