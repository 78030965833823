import {
  EstimationForm,
} from '@root/domain/Model/Wizard'
import i18n from '@root/plugins/i18n'
import {
  routes,
} from '@root/routes/wizard-routes'
import capitalFurnitureEstimator from '@root/domain/Model/CapitalFurnitureEstimator'
import {
  HousingType,
} from '@root/domain/Model/HousingType'

const messages: Record<routes, (state: EstimationForm) => string[]> = {
  [routes.ADDRESS]: () => [
    'Chaque adresse a un niveau de risque différent.',
    'Quelle est l\'<span class="font-bold">adresse du logement</span> que vous souhaitez assurer ?'
  ],
  [routes.HOUSE_TYPE]: () => [
    'Bienvenue sur notre comparateur ! \n Nous vous aiderons à trouver la meilleure assurance habitation en seulement 3 minutes.',
    'Pour commencer, dans quel <span class="font-bold">type de logement</span> habitez vous ?'
  ],
  [routes.HOUSE_FLOOR]: () => [
    'À quel étage se situe votre appartement ?'
  ],
  [routes.OCCUPATION_STATUS]: () => [
    'Êtes-vous ?'
  ],
  [routes.RESIDENCE_TYPE]: () => [
    'Est-ce votre <span class="font-bold">résidence principale</span> ?'
  ],
  [routes.USER_RESIDENCE_ADDRESS]: () => [
    'Quelle est votre adresse principale ? Elle est nécessaire pour obtenir un devis chez certains assureurs.'
  ],
  [routes.HOUSE_ROOMS]: () => [
    'Combien de <span class="font-bold">pièces de vie</span> possède votre logement ?',
    '⛔ <span class="font-bold">Ne comptez pas</span> les cuisines, salles de bain, toilettes et dressings. \n ✅ Pour toute pièce au-delà de 40 m2, comptez double. Si votre cuisine est ouverte, ajoutez sa surface à celle du salon.'
  ],
  [routes.HOUSE_SURFACE]: () => [
    'Quelle est la <span class="font-bold">surface habitable</span> de votre logement ?',
    '⛔ <span class="font-bold">Excluez</span> tout espace (pièce, combles et sous-sols) non aménagé de moins d\'1 mètre 80 de hauteur.'
  ],
  [routes.FACILITIES]: () => [
    'Possédez-vous l’une de ces pièces supplémentaires ?',
    '✅ Il s’agit de toute pièce séparée de votre logement, fermée et de plus de 9 m2 (garage, véranda, loggia, cave, chalet de jardin, etc). \n ⛔ Un garage sous votre logement ou une place de parking d\'immeuble ne sont pas des dépendances.'
  ],
  [routes.FACILITIES_SURFACE]: state => [
    i18n.t('surface_dependencies_question', {
      count: state.facilities?.length ?? 4,
    })
  ],
  [routes.FAMILY_SITUATION]: () => [
    'Parfait ! Et <span class="font-bold">avec qui</span> habitez-vous ?'
  ],
  [routes.ACOMMODATION_STATUS]: () => [
    'Votre bien est-il  <span class="font-bold">assuré actuellement</span> ?'
  ],
  [routes.CONTRACT_TERMINATED]: () => [
    'Avez-vous été <span class="font-bold">résilié par un assureur</span> au cours des 3 dernières années ?'
  ],
  [routes.AMOUNT_OF_CLAIMS]: () => [
    'Combien de <span class="font-bold">sinistre</span> avez-vous déclaré au cours des 3 dernières années ?'
  ],
  [routes.FURNITURE_CAPITAL]: state => {
    const estimated = capitalFurnitureEstimator(parseInt(state.housing_surface, 10), parseInt(state.housing_rooms, 10), state.housing_type as HousingType)

    const description = (state.housing_type && state.housing_surface && state.housing_surface)
      ? i18n.t(`capitalFurniture.information_${state.housing_type}`, {
        surface: state.housing_surface,
        value: Intl.NumberFormat('fr-FR').format(estimated),
        interpolation: {
          escapeValue: false,
        },
        defaultValue: '',
      })
      : ''

    return [
      'Quel est le <span class="font-bold">capital mobilier</span> à assurer ?',
      description
    ]
  },
  [routes.CONTRACT_START_DATE]: () => [
    'Parfait ! \n Choisissez maintenant votre <span class="font-bold">date de début de contrat</span> souhaitée ?'
  ],
  [routes.SUMMARY]: () => [
    'Nous avons besoin de <strong>vos coordonnées</strong> pour obtenir vos devis personnalisés.',
    'Cela nous permettra de vous mettre en relation avec les assureurs et de vous aider en cas de besoin. 👌'
  ],
  [routes.AGE]: () => [
    'Quelle est votre <span class="font-bold">date de naissance</span> ?'
  ],
}

export default messages
