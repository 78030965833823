import './App.css'

import {
  Outlet,
} from 'react-router-dom'
import {
  Default,
} from '@selectra-it/selectra-ui'

import Header from '@components/Header/Header'
import useScrollToTop from '@hooks/useScrollToTop'
import useRemoveTrailingSlash from '@hooks/useRemoveTrailingSlash'
import useIsOfferPage from '@hooks/useIsOfferPage'
import ProgressBar from '@components/Wizard/ProgressBar'

import {
  usePersistUtmParameters,
} from './hooks/usePersistUtmParameters'

function App () {
  useScrollToTop()
  useRemoveTrailingSlash()
  usePersistUtmParameters()

  const isOfferPage = useIsOfferPage()

  return (
    <div className="overflow-x-hidden">
      <Default header={<>
        <>
          <Header />
          {!isOfferPage && <ProgressBar />}
        </>
      </>}
      >
        <Outlet />
      </Default>
    </div>
  )
}

export default App
