import {
  GoogleAutocomplete,
} from '@selectra-it/selectra-ui'
import {
  forwardRef,
} from 'react'

import {
  Address,
  Nullable,
} from '@root/domain/Model/Wizard'
import {
  useLazyGetInseeCodeQuery,
} from '@root/services/address/insee'

interface FullAddressProps {
  id: string,
  value: Nullable<Address>,
  onChange: (address: Address | null) => void,
  name: string,
}

const FullAddress = forwardRef<HTMLInputElement, FullAddressProps>(({
  id,
  value,
  onChange,
  name,
}, ref) => {
  const [trigger] = useLazyGetInseeCodeQuery()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeHandler = async (address: any | null) => {
    if (!address) {
      onChange(null)
      return
    }

    const {
      data,
    } = await trigger(address.formatted)

    if (!data) {
      onChange(null)
      return
    }

    onChange({
      city: address.city,
      country: address.country,
      formatted: address.formatted,
      geometry: address.geometry,
      postal_code: address.postal_code,
      street_name: address.street_name,
      street_number: address.street_number,
      insee: data.insee,
    })
  }

  return (
    <GoogleAutocomplete
      id={id}
      libraries={['places', 'marker']}
      value={value}
      apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}
      componentRestrictions={{
        country: 'FR',
      }}
      label={'Numéro, rue et code postal'}
      ref={ref}
      name={name}
      onChange={onChangeHandler}
    />
  )
})

FullAddress.displayName = 'FullAddress'

export default FullAddress
