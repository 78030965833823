import {
  apiService,
} from '@services/apiService'

interface FloorLevel {
  value: string,
  label: string,
}

interface FloorLevelResponseItem {
  key: string,
  label: string,
}

interface FloorLevelsResponse {
  items: FloorLevelResponseItem[]
}

export const insurerApi = apiService.injectEndpoints({
  endpoints: builder => ({
    getFloorLevelTypes: builder.query<FloorLevel[], void>({
      query: () => 'api/home/floor-levels',
      transformResponse: (response: FloorLevelsResponse) => {
        return response.items.map(item => ({
          label: item.label,
          value: item.key,
        }))
      },
      keepUnusedDataFor: 60 * 10,
    }),
  }),
})

export const {
  useGetFloorLevelTypesQuery,
} = insurerApi
