import {
  createSelector,
} from '@reduxjs/toolkit'

import {
  Insurer,
} from '@root/domain/Model/Insurer'
import {
  RootState,
} from '@root/store/Reducer'

import {
  insurerApi,
} from './index'

const baseSelector = insurerApi.endpoints.getInsurers.select()

export const selectUsersResult = insurerApi.endpoints.getInsurers.select()

const emptyUsers: Insurer[] = []

export const selectAllInsurers = createSelector(
  baseSelector,
  insurersResult => insurersResult?.data ?? emptyUsers
)
export const selectActiveInsurerCount = createSelector(
  selectAllInsurers,
  insurers => insurers.filter(insurer => insurer.activeHomeInsurer).length
)

export const selectInsurerById = createSelector(
  selectAllInsurers,
  (state: RootState, insurerId: string) => insurerId,
  (insurers: Insurer[], insurerId: string) => {
    return insurers.find((user: { id: string }) => user.id === insurerId)
  }
)
