export enum routes {
  ADDRESS = '/adresse',
  HOUSE_TYPE = '/',
  HOUSE_FLOOR = '/etage',
  OCCUPATION_STATUS = '/statut',
  RESIDENCE_TYPE = '/residence',
  USER_RESIDENCE_ADDRESS = '/adresse-principale',
  HOUSE_ROOMS = '/pieces',
  HOUSE_SURFACE = '/surface',
  FACILITIES = '/dependance',
  FACILITIES_SURFACE = '/surface-dependance',
  FAMILY_SITUATION = '/situation-familiale',
  ACOMMODATION_STATUS = '/statut-assurance',
  CONTRACT_TERMINATED = '/resiliation-assureur',
  AMOUNT_OF_CLAIMS = '/sinistre',
  FURNITURE_CAPITAL = '/capital-mobilier',
  CONTRACT_START_DATE = '/debut-contrat',
  SUMMARY = '/recapitulatif',
  AGE = '/age'
}
