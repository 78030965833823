import {
  Stepper as BaseStepper,
  Status,
} from '@selectra-it/selectra-ui'
import {
  matchPath, useLocation,
} from 'react-router-dom'

import {
  StepperGroup, steps,
} from '@root/routes/wizard'

const StepperGroupOrder: Array<StepperGroup> = [StepperGroup.ACCOMMODATION, StepperGroup.COVERAGE, StepperGroup.CONTACT_INFORMATION]

const StepperSteps = [
  {
    step: 1,
    name: 'Bénéficiaires',
    status: 2,
  },
  {
    step: 2,
    name: 'Couverture',
    status: 0,
  },
  {
    step: 3,
    name: 'Coordonnées',
    status: 1,
  }
]

const Stepper = () => {
  const {
    pathname,
  } = useLocation()

  const currentStep = steps.find(step => matchPath(step.path, pathname))

  const stepGroupIndex = StepperGroupOrder.findIndex(step => step === currentStep?.group)

  const computedSteps = StepperSteps.map((step, index) => {
    const status = stepGroupIndex > index
      ? Status.COMPLETED
      : stepGroupIndex === index ? Status.ACTIVE : Status.PENDING

    return {
      ...step,
      status,
    }
  })

  return (
    <BaseStepper steps={computedSteps} />
  )
}

export default Stepper
