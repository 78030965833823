import {
  useSelector,
} from 'react-redux'

import Icon from '@components/Icon'
import {
  Document,
} from '@root/domain/Model/Offer'
import {
  useGetDocumentTypesQuery,
} from '@root/services/documents'
import {
  selectDocumentTypeByKey,
} from '@root/services/documents/selectors'
import {
  RootState,
} from '@root/store/Reducer'

interface DocumentListItemProps {
  document: Document
}

const DocumentListItem = ({
  document,
}: DocumentListItemProps) => {
  const {
    offerId, id, type,
  } = document

  const {
    data,
  } = useGetDocumentTypesQuery()

  const documentType = useSelector((state: RootState) => selectDocumentTypeByKey(state, type))

  if (!data || !documentType) {
    return (<></>)
  }

  return (
    <div className='flex items-center gap-1 text-left'>
      <Icon className='inline-block fill-success-300' name='file download'
        size={20}
      />
      <a target="_blank" href={`${import.meta.env.VITE_BASE_URL}/api/home/offers/${offerId}/documents/${id}`}
        className='text-primary-400 underline visited:text-purple-600 hover:text-primary-500' rel="noreferrer"
      >
        {documentType.label}
      </a>
    </div>
  )
}

export default DocumentListItem
