import {
  InteractionMessage,
} from '@selectra-it/selectra-ui'

import {
  EstimationStatus,
} from '@root/domain/Model/Estimation'
import {
  useAppSelector,
} from '@root/store/Reducer'
import {
  selectActiveInsurerCount,
} from '@root/services/insurers/selectors'

export enum STATUS {
  LOADED_NO_OFFERS,
  LOADED_WITH_OFFERS,
  FETCHING,
}

export const messagesDictionary = {
  [STATUS.LOADED_NO_OFFERS]: () => ['Je suis navré, je ne suis pas parvenu à trouver d’offre qui correspond à votre besoin.'],
  [STATUS.LOADED_WITH_OFFERS]: (firstName: string, insurerCount: number) => [
    `${firstName}, voici la liste des offres les plus adaptées à votre profil 👍`,
    `${insurerCount} partenaires interrogés. Résultats non exhaustifs.`
  ],
  [STATUS.FETCHING]: () => ['Merci de patienter, je suis en train d’interroger les assureurs.'],
}

interface OffersMessageProps {
  status: EstimationStatus,
  items: number,
  name: string,
}

const OffersMessage = ({
  name,
  status,
  items,
}: OffersMessageProps) => {
  const computedStatus = status !== EstimationStatus.COMPLETE
    ? STATUS.FETCHING
    : items > 0 ? STATUS.LOADED_WITH_OFFERS : STATUS.LOADED_NO_OFFERS

  const insurerCount = useAppSelector(state => selectActiveInsurerCount(state))

  const messages = messagesDictionary[computedStatus](name, insurerCount)

  return (
    <div className='flex gap-4 px-4 md:px-6'>
      <div className='grid grid-cols-1 gap-4'>
        <div className="flex w-full gap-2 md:gap-4">
          <InteractionMessage messages={messages} />
        </div>
      </div>
    </div>
  )
}

export default OffersMessage
