import {
  Insurer,
} from './Insurer'
import {
  Nullable,
} from './Wizard'

export interface Coverage {
  id: string;
  key: string;
}

export interface Guarantee {
  id: string;
  franchise: number;
  premium: number;
  threshold: number,
  coverage: Coverage;
}

export interface Document {
  id: string;
  type: string;
  name: string;
  offerId: string;
}

export interface Pack {
  id: string;
  premium: number;
  coverages: Coverage[];
}

export interface Offer {
  id: string;
  estimationId: string;
  insurerId: string;
  providerId: Nullable<string>;
  title: string;
  externalId: string;
  price: number;
  monthlyPrice: number;
  franchise: number,
  closingType: string;
  guarantees: Guarantee[];
  url: string;
  documents: Document[];
  packs: Pack[];
  rating: number,
  rank: number,
}

export const calculateFinalPrice = (insurer: Insurer | undefined, price: number) => {
  const fee = (insurer?.administrationFee ?? 0) / 12

  const withFee = price + fee * 100

  return withFee
}
