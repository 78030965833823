import Icon from '@components/Icon'

interface HeaderProps {
  title: string,
  icon?: string,
}

const Header = ({
  title,
  icon,
}: HeaderProps) => {
  return (
    <div className="inline-flex h-6 items-center justify-start gap-1 pr-4">
      <div className="relative size-6">
        {icon &&
        <Icon name={icon} className='fill-primary-300'
          size={24}
        />}
      </div>
      <div className="h-5 w-[268px] truncate text-base font-bold leading-tight text-neutral-700">{title}</div>
    </div>
  )
}

export default Header
