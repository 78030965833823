import {
  AnalyticsEvent,
} from '@root/analytics/Events/AnalitycsEvent'

type WindowWithDataLayer = Window & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataLayer: Record<string, any>[];
};

declare const window: WindowWithDataLayer

export const useTracking = () => {
  const trackEvent = ({
    type, ...params
  }: AnalyticsEvent): void => {
    window.dataLayer.push({
      event: type,
      ...params,
    })
  }

  return {
    trackEvent,
  }
}
