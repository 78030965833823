import {
  useGetFamilitySitutationTypesQuery,
} from '@root/services/familySituation'
import {
  useGetAmountOfAccidentTypesQuery,
} from '@root/services/accidentTypes'
import {
  useGetFloorLevelTypesQuery,
} from '@root/services/floorLevels'
import {
  useGetHousingTypesQuery,
} from '@root/services/types/HousingTypes'
import {
  useGetOccupationStatusTypesQuery,
} from '@root/services/occupationStatuses'

const useOptionsPrefetch = () => {
  useGetAmountOfAccidentTypesQuery()
  useGetFamilitySitutationTypesQuery()
  useGetFloorLevelTypesQuery()
  useGetHousingTypesQuery()
  useGetOccupationStatusTypesQuery()
}

export default useOptionsPrefetch
