import {
  Estimation,
} from '@root/domain/Model/Estimation'
import {
  Offer,
} from '@root/domain/Model/Offer'
import {
  apiService,
} from '@services/apiService'
import {
  Nullable,
} from '@root/domain/Model/Wizard'

import {
  InsurerMerOption,
} from '../insurers'

export const BEST_PRICE = 'BEST_PRICE'
export const BEST_RATING = 'BEST_RATING'
export const FREE_TRIAL_1_MONTH = 'FREE_TRIAL_1_MONTH'

export interface Tags {
  [BEST_PRICE]: string | string[],
  [BEST_RATING]: string | string[],
  [FREE_TRIAL_1_MONTH]: string | string[]
}

interface EstimationWithOffers {
  items: Offer[],
  estimation: Estimation,
  tags: Tags,
}

export interface GetOffersQuery {
  estimationId: string,
  field?: string,
  direction?: string,
}

export enum MerStatus {
  NEW = 'new',
  IN_PROGRESS = 'in_progress',
  DONE = 'done',
  ERROR = 'error',
}

interface FetchMerResponse {
  status: MerStatus
  description: string
  type: InsurerMerOption
  url: string
}

export interface ImmediateMerCallbackDate {
  isImmediate: true,
  date: null,
}

export interface ScheduledMerCallbackDate {
  isImmediate: false,
  date: string,
}

interface CreateMerMutation {
  id: string,
  type: InsurerMerOption,
  offerId: string,
  callback_date: Nullable<ImmediateMerCallbackDate | ScheduledMerCallbackDate>,
}

interface FetchMerQuery {
  offerId: string,
  merId: string,
}

export const OffersApi = apiService
  .enhanceEndpoints({
    addTagTypes: ['Offers'],
  })
  .injectEndpoints({
    endpoints: builder => ({
      createEstimation: builder.mutation({
        query: ({
          uuid, ...data
        }) => ({
          url: `api/home/estimation/${uuid}`,
          method: 'POST',
          body: {
            ...data,
            _method: 'PUT',
          },
        }),
      }),
      getOffersByEstimationId: builder.query<EstimationWithOffers, GetOffersQuery>({
        query: ({
          estimationId,
          direction,
          field,
        }) => `api/home/estimation/${estimationId}/offers?sort[0][direction]=${direction}&sort[0][field]=${field}`,
        providesTags: result => {
          const tags = result
            ? [
              ...result.items.map(({
                id,
              }) => ({
                type: 'Offers' as const, id,
              })),
              {
                type: 'Offers' as const, id: 'LIST',
              }
            ]
            : [{
              type: 'Offers' as const, id: 'LIST',
            }]

          return tags
        },
      }),
      createMer: builder.mutation<void, CreateMerMutation>({
        query: ({
          offerId, id, ...data
        }) => ({
          url: `api/home/offers/${offerId}/mer/${id}`,
          method: 'POST',
          body: {
            ...data,
          },
        }),
      }),
      fetchMer: builder.query<FetchMerResponse, FetchMerQuery>({
        query: ({
          offerId, merId,
        }) => ({
          url: `api/home/offers/${offerId}/mer/${merId}`,
          method: 'GET',
        }),
      }),
      createLinkForOffer: builder.mutation({
        query: ({
          offerId, ...data
        }) => ({
          url: `api/home/offers/${offerId}/links`,
          method: 'POST',
          body: {
            ...data,
          },
        }),
        invalidatesTags: (result, error, {
          offerId,
        }) => {
          return [{
            type: 'Offers', id: offerId,
          }]
        },
      }),
    }),
  })

export const {
  useGetOffersByEstimationIdQuery,
  useCreateEstimationMutation,
  useCreateLinkForOfferMutation,
  useCreateMerMutation,
  useFetchMerQuery,
} = OffersApi
