import {
  Outlet,
  useParams, useSearchParams,
} from 'react-router-dom'

import Offers from '@features/offer/Offers'
import SubscriptionModal from '@components/offers/Subscription/SubscriptionModal'
import RatingModal from '@components/offers/RatingModal/RatingModal'

import LoadingPage from './LoadingPage'

const OffersPage = () => {
  const params = useParams()
  const [searchParams] = useSearchParams()
  const {
    estimationId,
  } = params

  return (
    <>
      {(searchParams.get('preload')) && <LoadingPage />}
      <Offers estimationId={estimationId} />
      <SubscriptionModal />
      <RatingModal />
      <Outlet />
    </>
  )
}

export default OffersPage
