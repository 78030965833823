export const SIZES = {
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl',
}

const sizes = {
  [SIZES.SM]: 'w-4 h-4',
  [SIZES.MD]: 'w-8 h-8',
  [SIZES.LG]: 'w-12 h-12',
  [SIZES.XL]: 'w-16 h-16',
}

export default sizes
