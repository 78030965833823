import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: import.meta.env.VITE_GTM_ID,
}

window.dataLayer = []

if (import.meta.env.PROD && import.meta.env.VITE_ENV === 'production' && import.meta.env.VITE_GTM_ID) {
  TagManager.initialize(tagManagerArgs)
}
