import qs from 'qs'
import {
  createApi, fetchBaseQuery,
} from '@reduxjs/toolkit/query/react'

// initialize an empty api service that we'll inject endpoints into later as needed
export const apiService = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE_URL,
    prepareHeaders: headers => {
      headers.set('Accept', 'application/json')
      headers.set('Content-Type', 'application/json')
      return headers
    },
    paramsSerializer: params => qs.stringify(params),
  }),
  keepUnusedDataFor: 10,
  endpoints: () => ({}),
})
