import {
  FC,
} from 'react'
import {
  FieldError, UseFormRegister,
} from 'react-hook-form'
import {
  ErrorMessages, InputGroup, RADIO_STATUS, RadioStacked, RadioStackedContainer, getErrorMessages,
} from '@selectra-it/selectra-ui'

import {
  useGetFloorLevelTypesQuery,
} from '@root/services/floorLevels'
import {
  EstimationForm,
} from '@root/domain/Model/Wizard'

interface HouseFloorProps {
  errors?: FieldError,
  register: UseFormRegister<EstimationForm>,
  onAnimationFinished: () => void,
}

const HouseFloor: FC<HouseFloorProps> = ({
  errors,
  register,
  onAnimationFinished,
}) => {
  const {
    data, isLoading, error,
  } = useGetFloorLevelTypesQuery()

  if (isLoading || error || !data?.length) {
    return (
      <div />
    )
  }

  const fields = register('housing_floor_level', {
    required: true,
  })

  return (
    <InputGroup errors={getErrorMessages(ErrorMessages, errors?.type)}>
      <RadioStackedContainer>
        {data.map(item => {
          return (
            <RadioStacked key={item.value} id={item.value}
              item={item}
              animate={true}
              onAnimationFinished={onAnimationFinished}
              {...fields}
              status={errors ? RADIO_STATUS.DANGER : RADIO_STATUS.DEFAULT}
            />
          )
        })}
      </RadioStackedContainer>
    </InputGroup>
  )
}

export default HouseFloor
