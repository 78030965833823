import {
  ReactElement,
} from 'react'
import {
  Checkbox,
  RADIO_STATUS,
  RadioBoxContainer,
} from '@selectra-it/selectra-ui'
import {
  FieldError, FieldValues, Merge, Path, UseFormRegister,
} from 'react-hook-form'

import {
  useGetFacilityTypesQuery,
} from '@root/services/facilities'
import Icon from '@components/Icon'

interface FacilityItem {
  icon: string;
  label: string;
  value: string;
  withSurface: boolean;
}

interface FacilitiesInputProps<T extends FieldValues> {
  errors?: Merge<FieldError, (FieldError | undefined)[]> | undefined;
  register: UseFormRegister<T>,
  name: Path<T>
}

const FacilitiesInput: <T extends FieldValues>(p: FacilitiesInputProps<T>) => ReactElement = ({
  register,
  name,
  errors,
}) => {
  const {
    data, isLoading, error,
  } = useGetFacilityTypesQuery()

  if (isLoading || error || !data?.length) {
    return (
      <></>
    )
  }

  const items = data?.map(item => ({
    icon: item.icon,
    label: item.label.text,
    value: item.value,
    withSurface: item.withSurface,
  }))

  return (
    <RadioBoxContainer>
      {items.map((item: FacilityItem) => (
        <Checkbox
          key={item.value}
          id={item.value}
          {...register(name)}
          item={{
            icon: () => (
              <Icon name={item.icon} size={36}
                className={'m-auto mb-2 block size-12'}
              />
            ),
            value: item.value,
            label: item.label,
          }}
          status={errors ? RADIO_STATUS.DANGER : RADIO_STATUS.DEFAULT}
        />
      ))}
    </RadioBoxContainer>
  )
}

export default FacilitiesInput
