import {
  useEffect,
} from 'react'
import {
  useLocation, useNavigate,
} from 'react-router'

const useRemoveTrailingSlash = () => {
  const {
    pathname, search, hash,
  } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (pathname.slice(-1) === '/') {
      navigate(`${pathname.replace(/\/+$/, '')}${search}${hash}`, {
        replace: true,
      })
    }
  }, [pathname, search, hash, navigate])

  return null
}

export default useRemoveTrailingSlash
