import {
  apiService,
} from '@services/apiService'

interface OccupationStatus {
  value: string,
  label: string,
}

interface OccupationStatusesResponseItem {
  key: string,
  label: string,
}

interface OccupationStatusesResponse {
  items: OccupationStatusesResponseItem[];
}

export const insurerApi = apiService.injectEndpoints({
  endpoints: builder => ({
    getOccupationStatusTypes: builder.query<OccupationStatus[], void>({
      query: () => 'api/home/occupation-statuses',
      transformResponse: (response: OccupationStatusesResponse) => {
        return response.items.map(item => ({
          label: item.label,
          value: item.key,
        }))
      },
      keepUnusedDataFor: 60 * 10,
    }),
  }),
})

export const {
  useGetOccupationStatusTypesQuery,
} = insurerApi
