import {
  rest,
} from 'msw'

import documentTypes from '@fixtures/document-types.json'
import insurers from '@fixtures/insurers.json'
import coverages from '@fixtures/coverages.json'
import estimation from '@fixtures/offers.json'
import facilities from '@fixtures/facility-types.json'
import insurerMerOptions from '@fixtures/insurer-mer-options.json'
import user from '@fixtures/user.json'

const handlers = [
  rest.get('*/api/home/estimation/:estimationId/offers', (req, res, ctx) => {
    return res(ctx.json(estimation))
  }),
  rest.get('*/api/user/:userId', (req, res, ctx) => {
    return res(ctx.json(user))
  }),
  rest.patch('*/api/user/:userId', (req, res, ctx) => {
    ctx.status(204)
    return res()
  }),
  rest.get('*/api/home/document-types', (req, res, ctx) => {
    return res(ctx.json(documentTypes))
  }),
  rest.get('*/api/home/coverages', (req, res, ctx) => {
    return res(ctx.json(coverages))
  }),
  rest.get('*/api/insurers', (req, res, ctx) => {
    return res(ctx.json(insurers))
  }),
  rest.get('*/api/home/facilities', (req, res, ctx) => {
    return res(ctx.json(facilities))
  }),
  rest.get('*/api/phone*', (req, res, ctx) => {
    ctx.status(200)
    return res()
  }),
  rest.get('*/api/insurers/*/mer-options', (req, res, ctx) => {
    return res(ctx.json(insurerMerOptions))
  }),
  rest.post('*/api/home/estimation/*/offers/*/mer/*', (req, res, ctx) => {
    ctx.status(201)
    return res()
  })
]

export {
  handlers
}
