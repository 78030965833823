import {
  createSelector,
  createSlice,
} from '@reduxjs/toolkit'

import {
  RootState,
} from '@root/store/Reducer'

export const GUARANTEE_MODAL = 'guarantees'
export const RATING_MODAL = 'rating'

type MODALS = typeof GUARANTEE_MODAL | typeof RATING_MODAL

interface ModalState {
  [GUARANTEE_MODAL]: boolean,
  [RATING_MODAL]: boolean,
}

const initialState: ModalState = {
  [GUARANTEE_MODAL]: false,
  [RATING_MODAL]: false,
}

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    toggleRatingModal: state => {
      state.rating = !state.rating
    },
    toggleGuaranteesModal: state => {
      state.guarantees = !state.guarantees
    },
  },
})

export const isModalOpen = createSelector(
  (state: RootState) => state,
  (state: RootState, modal: MODALS) => modal,
  (state: RootState, modal: MODALS) => state.modal[modal]
)

export const {
  toggleGuaranteesModal,
  toggleRatingModal,
} = modalSlice.actions

export default modalSlice.reducer
