import {
  HousingType,
} from './HousingType'

const residenceTypeMultiplier: Record<HousingType, number> = {
  flat: 1,
  house: 2,
}

const estimator = (surface: number, rooms: number, residenceType: HousingType) => {
  const delta = (
    -7.90741327389184 +
    0.218101940887938 * surface +
    2.28157112682207 * rooms +
    1.75260092115801 * residenceTypeMultiplier[residenceType]
  )

  const nearest5Increment = Math.ceil(delta / 5) * 5

  return nearest5Increment * 1000
}

export default estimator
