import {
  RADIO_STATUS,
} from '@selectra-it/selectra-ui'
import {
  LiteralUnion, RegisterOptions,
} from 'react-hook-form'

type useGetRadiosStatusProps = LiteralUnion<keyof RegisterOptions, string> | undefined

const useGetRadioStatus = (error: useGetRadiosStatusProps) => {
  if (error) { return RADIO_STATUS.DANGER }

  return RADIO_STATUS.DEFAULT
}

export default useGetRadioStatus
