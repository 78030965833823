import {
  useRef,
} from 'react'

import HouseType from '@components/form/estimation/HouseType'
import useEstimationWizardForm from '@hooks/estimation/useEstimationWizardForm'
import {
  HousingType,
} from '@root/domain/Model/HousingType'
import {
  EstimationForm,
} from '@root/domain/Model/Wizard'
import NavigationButtons from '@components/form/NavigationButtons'

import {
  WizardStepProps,
} from '../EstimationFormWizard'

export interface HouseTypeInformationForm extends EstimationForm {
  housing_type: HousingType,
}

const HouseTypeInformation = ({
  next,
}: WizardStepProps) => {
  const formRef = useRef<HTMLFormElement|null>(null)

  const {
    form: {
      register, formState: {
        errors,
      },
    },
    onSubmitHandler, onBackHandler,
  } = useEstimationWizardForm({
    mode: 'onChange',
    revalidateMode: 'onChange',
    next,
  })

  const onAnimationFinishedHandler = () => {
    formRef?.current?.dispatchEvent(new Event('submit', {
      cancelable: true,
      bubbles: true,
    }))
  }

  return (
    <form ref={formRef}
      onSubmit={onSubmitHandler(formRef)}
      className='flex w-full grow flex-col justify-between space-y-6'
    >
      <HouseType
        onAnimationFinished={onAnimationFinishedHandler}
        register={register}
        errors={errors.housing_type}
      />

      <NavigationButtons onBackHandler={onBackHandler} />
    </form>
  )
}

export default HouseTypeInformation
