import {
  DocumentType,
} from '@root/domain/Model/DocumentType'
import {
  apiService,
} from '@services/apiService'

interface DocumentTypesResponse {
  items: DocumentType[];
}

export const insurerApi = apiService.injectEndpoints({
  endpoints: builder => ({
    getDocumentTypes: builder.query<DocumentType[], void>({
      query: () => 'api/home/document-types',
      transformResponse: (response: DocumentTypesResponse) => {
        return response.items
      },
    }),
  }),
})

export const {
  useGetDocumentTypesQuery,
} = insurerApi
