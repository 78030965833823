import {
  formatDate,
} from '@root/util/date'

export interface CallbackDate {
  label: string,
  id: string,
}

export const callbackDateNow = {
  label: 'Le plus vite possible',
  id: 'now',
}

const tomorrow = new Date()
tomorrow.setDate(tomorrow.getDate() + 1)

const afterTomorrow = new Date(tomorrow)
afterTomorrow.setDate(tomorrow.getDate() + 1)

export const getCallbackDateOptions = (): CallbackDate[] => [
  callbackDateNow,
  {
    label: formatDate(tomorrow),
    id: formatDate(tomorrow),
  },
  {
    label: formatDate(afterTomorrow),
    id: formatDate(afterTomorrow),
  }
]
