import {
  FC, ReactNode,
} from 'react'
import ReactModal from 'react-modal'

import Icon from '@components/Icon'

import {
  Size,
} from './Size'

interface ModalProps extends ReactModal.Props {
  action?: ReactNode,
  size: Size
}

const classes: { [T in Size]: string } = {
  [Size.SM]: 'absolute bottom-0 rounded-t-3xl md:static md:bottom-auto md:rounded-b-3xl inset-auto bg-white px-4 py-[16px] w-full md:w-90 outline-none',
  [Size.MD]: 'absolute bottom-0 rounded-t-3xl md:static md:bottom-auto md:rounded-b-3xl inset-auto bg-white px-4 py-[16px] w-full md:w-90 outline-none',
  [Size.LG]: 'absolute bottom-0 rounded-t-3xl md:static md:bottom-auto md:rounded-b-3xl inset-auto bg-white px-4 py-[16px] w-full md:w-[640px] outline-none',
}

const Modal: FC<ModalProps> = ({
  action, size, children, onRequestClose, ...modalProps
}) => {
  const className = classes[size]

  return (
    <>
      <ReactModal
        {...modalProps}
        onRequestClose={onRequestClose}
        className={className}
        overlayClassName="fixed inset-0 flex justify-center items-center bg-black/75 opacity-1 z-20"
        style={{
          overlay: {
            transition: 'all 500ms ease-in-out',
          },
          content: {},
        }}
        closeTimeoutMS={500}
      >
        <div className="absolute right-3 top-3 z-30 cursor-pointer" onClick={onRequestClose}>
          <Icon name='cross' className='fill-neutral-300'
            size={24}
          />
        </div>
        {children}
      </ReactModal>
      {action}
    </>
  )
}

export default Modal
