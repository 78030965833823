import Icon from '@components/Icon'
import {
  CustomerService,
} from '@root/domain/Model/Insurer'

const IconMap = {
  [CustomerService.online_chat]: 'chat',
  [CustomerService.phone]: 'phone',
}

interface CustomerServiceIconProps {
  type: CustomerService
}

const CustomerServiceIcon = ({
  type,
}: CustomerServiceIconProps) => {
  const icon = IconMap[type]

  return (
    <Icon name={icon} className='inline-block fill-success-300'
      size={20}
    />
  )
}

export default CustomerServiceIcon
