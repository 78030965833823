import {
  PayloadAction,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit'

import {
  RootState,
} from '@root/store/Reducer'

interface OfferSortingState {
  field: string,
  direction: string,
}

const initialState: OfferSortingState = {
  field: 'yearly_price',
  direction: 'asc',
}

const offerSortingSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setSort: (state, action: PayloadAction<OfferSortingState>) => {
      state.field = action.payload.field
      state.direction = action.payload.direction
    },
  },
})

export const selectSortingValues = createSelector(
  (state: RootState) => state,
  (state: RootState) => state.offerSort
)

export const {
  setSort,
} = offerSortingSlice.actions

export default offerSortingSlice.reducer
