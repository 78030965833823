import {
  useCallback,
} from 'react'
import {
  useTransition, animated,
} from '@react-spring/web'
import {
  Navigate,
  Route, Routes, useLocation, useNavigate,
} from 'react-router-dom'
import {
  InteractionMessage,
} from '@selectra-it/selectra-ui'

import {
  getRouteInformation,
  steps,
} from '@root/routes/wizard'
import messages from '@components/Wizard/messages'
import {
  useAppSelector,
} from '@root/store/Reducer'
import {
  getFormState,
} from '@root/services/formSlice'
import {
  useGetFacilityTypesQuery,
} from '@root/services/facilities'
import useOptionsPrefetch from '@hooks/estimation/useOptionsPrefetch'

const EstimationFormWizard = () => {
  const navigate = useNavigate()
  const location = useLocation()
  useOptionsPrefetch()
  const state = useAppSelector(state => getFormState(state))

  const facilityTypes = useGetFacilityTypesQuery()

  const interactionMessages = messages[location.pathname](state)

  const next = useCallback((data, options) => {
    const route = getRouteInformation(location.pathname, data, {
      facilityTypes: facilityTypes.data,
    })

    navigate(route.next.path, options)
  }, [location, navigate, facilityTypes])

  const transitions = useTransition(location, {
    from: {
      transform: 'translateX(10%)',
      opacity: 0,
    },
    enter: {
      transform: 'translateX(0%)',
      opacity: 1,
    },
    config: {
      duration: 300,
    },
  })

  return (
    <>
      <div className="container mx-auto mt-6 flex grow flex-col flex-wrap items-center px-5 md:px-0">
        <div className='flex w-full grow'>
          {transitions((styles, location) => (
            <animated.div style={styles} className='mb-6 flex w-full grow flex-col justify-between space-y-6 md:mb-24'>
              <div className="flex w-full gap-2 md:gap-4">
                <InteractionMessage messages={interactionMessages} />
              </div>

              <div className={'container m-auto flex w-full grow'} >
                <Routes location={location}>
                  {steps.map(route =>
                    <Route key={route.path} path={route.path}
                      element={<route.component next={next} />}
                    />)}
                  <Route path='*' element={<Navigate to={''} replace={true} />} />
                </Routes>
              </div>
            </animated.div>
          ))}
        </div>
      </div>
    </>
  )
}

export default EstimationFormWizard
