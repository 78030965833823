import {
  FC,
} from 'react'
import {
  FieldError,
  UseFormRegister,
} from 'react-hook-form'
import {
  ErrorMessages,
  InputGroup,
  RADIO_STATUS,
  RadioBox, RadioBoxContainer, getErrorMessages,
} from '@selectra-it/selectra-ui'

import {
  useGetHousingTypesQuery,
} from '@root/services/types/HousingTypes'
import Icon from '@components/Icon'
import {
  EstimationForm,
} from '@root/domain/Model/Wizard'

interface HouseTypeProps {
  errors?: FieldError,
  register: UseFormRegister<EstimationForm>,
  onAnimationFinished: () => void,
}

const HouseType: FC<HouseTypeProps> = ({
  errors,
  register,
  onAnimationFinished,
}) => {
  const {
    error, data, isLoading,
  } = useGetHousingTypesQuery()

  if (isLoading || error) {
    return (
      <></>
    )
  }

  const items = data ?? []

  return (
    <InputGroup errors={getErrorMessages(ErrorMessages, errors?.type)}>
      <RadioBoxContainer>
        {items.map(item => (
          <RadioBox key={item.value}
            animate={true}
            onAnimationFinished={onAnimationFinished}
            id={item.value}
            {...register('housing_type', {
              required: true,
            })}
            item={{
              icon: () => (
                <Icon name={item.icon} size={36}
                  className={'m-auto mb-2 block size-12'}
                />
              ),
              value: item.value,
              label: item.label,
            }}
            status={errors ? RADIO_STATUS.DANGER : RADIO_STATUS.DEFAULT}
          />
        ))}
      </RadioBoxContainer>
    </InputGroup>
  )
}

export default HouseType
