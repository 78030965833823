import dayjs from '@root/plugins/dayjs'

export const isValidDate = (dateString: string): boolean => {
  const datePattern = /^(\d{2})\/(\d{2})\/(\d{4})$/

  const match = dateString.match(datePattern)

  if (!match) {
    return false // If the format doesn't match, it's invalid
  }

  const parsedDate = dayjs(dateString, 'DD/MM/YYYY', 'fr', true)

  // Check if the parsedDate is valid and the input matches the expected format
  return parsedDate.isValid()
}
