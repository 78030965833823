import {
  array,
  func,
  object,
} from 'prop-types'
import {
  useTranslation,
} from 'react-i18next'
import {
  TextInput, INPUT_STATUS, getErrorMessages, InputGroup, ErrorMessages,
} from '@selectra-it/selectra-ui'

import {
  startsWithVowel,
} from '@root/util/lang'
import {
  useGetFacilityTypesQuery,
} from '@root/services/facilities'

const FacilitiesSurface = props => {
  const {
    data, isLoading, error,
  } = useGetFacilityTypesQuery()

  const {
    t,
  } = useTranslation()

  const {
    facilities, register, errors,
  } = props

  const inputs = data
    ? data
      .filter(item => {
        return item.withSurface && facilities && facilities.includes(item.value)
      })
    : null

  if (isLoading || error) {
    return (
      <></>
    )
  }

  return (
    inputs
      .map(facility => {
        return (
          <InputGroup
            key={facility.value}
            errors={getErrorMessages({
              ...ErrorMessages,
              min: t('validation.surface'),
            }, errors?.[facility.value]?.type)}
          >
            <TextInput
              {...register(`facilitiesSurface.${facility.value}`, {
                required: true,
                min: 1,
              })}
              error={errors ? errors[facility.value] : null}
              label={t(`facility_surface.label-${facility.label.gender}-startsWithVowel_${startsWithVowel(facility.label.text)}`, {
                type: facility.label.text,
                interpolation: {
                  escapeValue: false,
                },
              })}
              type='number'
              status={errors && errors[facility.value] ? INPUT_STATUS.DANGER : INPUT_STATUS.DEFAULT}
            />
          </InputGroup>

        )
      })
  )
}

FacilitiesSurface.propTypes = {
  facilities: array,
  register: func.isRequired,
  errors: object,
  next: func.isRequired,
}

export default FacilitiesSurface
