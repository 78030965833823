import common from './common'
import wizard from './wizard'
import offers from './offers'
import validation from './validation'

export default {
  common,
  wizard,
  offers,
  validation,
}
