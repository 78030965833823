import {
  apiService,
} from '@services/apiService'

interface Coverage {
  id: string,
  key: string,
  label: string,
  shortLabel: string,
}

interface CoverageTypesResponse {
  items: Coverage[];
}

export const insurerApi = apiService.injectEndpoints({
  endpoints: builder => ({
    getCoverageTypes: builder.query<Coverage[], void>({
      query: () => 'api/home/coverages',
      transformResponse: (response: CoverageTypesResponse) => {
        return response.items
      },
    }),
  }),
})

export const {
  useGetCoverageTypesQuery,
} = insurerApi
