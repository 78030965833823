import {
  FC,
  useRef,
  useState,
} from 'react'
import {
  useDispatch,
} from 'react-redux'
import {
  Combobox, INPUT_STATUS,
} from '@selectra-it/selectra-ui'

import {
  setSort,
} from '@root/services/offers/offerSortingSlice'

interface Option {
  id: string,
  label: string,
  value: {
    field: string,
    direction: string,
  }
}

const OfferSorting: FC<Record<string, never>> = () => {
  const options: Option[] = [
    {
      id: 'yearly_price',
      label: 'Prix croissant',
      value: {
        field: 'yearly_price',
        direction: 'asc',
      },
    },
    {
      id: 'rating',
      label: 'Score Selectra décroissant',
      value: {
        field: 'rating',
        direction: 'desc',
      },
    },
    {
      id: 'franchise',
      label: 'Franchise croissante',
      value: {
        field: 'franchise',
        direction: 'asc',
      },
    }
  ]

  const comboboxRef = useRef<HTMLInputElement>(null)
  const [value, setValue] = useState<Option | null>(options[0])

  const dispatch = useDispatch()

  const onChange = (option: Option|null) => {
    if (option) {
      dispatch(setSort(option.value))
      setValue(option)
    }
  }

  return (
    <Combobox<Option>
      id='offer-sorting'
      anchor={{
        to: 'bottom end',
      }}
      ref={comboboxRef}
      displayValue={item => item?.label ?? ''}
      setQuery={() => null}
      item={value}
      setItem={onChange}
      items={options}
      label={'Trier par'}
      status={INPUT_STATUS.DEFAULT}
      name={'offer-sorting'}
      readOnly={true}
      canClearValue={false}
      dataCy="offer-sorting"
      immediate={true}
    />
  )
}

export default OfferSorting
