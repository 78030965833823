import {
  Coverage,
} from '@root/domain/Model/Offer'

import CoverageIcon from '../CoverageIcon'
import CoverageName from '../CoverageName'

interface OptionListItemCoverageProps {
  coverage: Coverage
}

const OptionListItemCoverage = ({
  coverage,
}: OptionListItemCoverageProps) => {
  return (
    <div className='flex items-center gap-2'>
      <CoverageIcon icon={coverage.key} />
      <CoverageName name={coverage.id} />
    </div>
  )
}

export default OptionListItemCoverage
