import {
  useParams,
  useSearchParams,
} from 'react-router-dom'
import {
  useEffect,
  useState,
} from 'react'
import {
  useSpring,
  animated,
  config,
} from '@react-spring/web'

import Icon from '@components/Icon'
import useGetOffersByEstimationId from '@hooks/offers/useGetOffersByEstimationId'
import VerticalCarrousel from '@components/ui/VerticalCarrousel'
import LoadingText from '@components/ui/Text/LoadingText'
import LoadingSpinner from '@components/Loader/LoadingSpinner/LoadingSpinner'

const phrases = [
  'Service complètement gratuit',
  'Votre ancien contrat résilié pour vous',
  'Même prix que sur le site de l’assureur'
]

const LoadingPage = () => {
  const params = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const [isOpen, setIsOpen] = useState<boolean>(searchParams.get('preload') === 'true')
  const styles = useSpring({
    from: {
      opacity: isOpen ? 0 : 1,
      transform: isOpen ? 'scale(0)' : 'scale(1)',
    },
    to: {
      opacity: isOpen ? 1 : 0,
      transform: isOpen ? 'scale(1)' : 'scale(0)',
    },
    leave: {
      opacity: isOpen ? 0 : 1,
      transform: isOpen ? 'scale(0)' : 'scale(1)',
    },
    config: config.gentle,
  })
  const [iterationCompleted, setIterationCompleted] = useState<boolean>(false)
  const {
    estimationId,
  } = params

  const {
    data,
  } = useGetOffersByEstimationId(estimationId || '')

  const itemsLoaded = data?.estimation.status === 'complete' && iterationCompleted

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>
    if (itemsLoaded) {
      timeout = setTimeout(() => {
        setIsOpen(false)
      }, 1800)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [itemsLoaded])

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>
    if (!isOpen) {
      timeout = setTimeout(() => {
        setSearchParams({})
      }, 1000)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [isOpen, setSearchParams])

  return (
    <animated.div className='fixed inset-0 z-20 flex h-screen w-screen items-center bg-primary-400' style={styles}>
      <div className='h-screen w-screen pt-[107px] md:pt-36'>
        <div className='flex flex-col items-center gap-4 md:gap-11'>
          {!itemsLoaded && <>
            <LoadingSpinner className='size-[71px] fill-primary-650 text-white md:size-[100px]' size='xl'/>
            <VerticalCarrousel onIterationCompleted={() => {
              setIterationCompleted(true)
            }}
            >
              {phrases.map(phrase => <LoadingText text={phrase} key={phrase}/>)}
            </VerticalCarrousel>
          </>}

          {itemsLoaded &&
          <>
            <Icon name='check' className='size-[71px] rounded-full bg-white fill-success-400 md:size-[100px]'
              size={24}
            />
            <LoadingText text={'Voici vos devis personnalisés !'} />
          </>}
        </div>
      </div>
    </animated.div>
  )
}

export default LoadingPage
