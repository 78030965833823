import {
  useTranslation,
} from 'react-i18next'
import {
  ErrorMessages,
  getErrorMessages,
  InputGroup,
} from '@selectra-it/selectra-ui'
import {
  useEffect, useMemo,
} from 'react'

import CapitalFurniture from '@components/form/estimation/CapitalFurniture'
import useEstimationWizardForm from '@hooks/estimation/useEstimationWizardForm'
import NavigationButtons from '@components/form/NavigationButtons'
import estimator from '@root/domain/Model/CapitalFurnitureEstimator'
import {
  HousingType,
} from '@root/domain/Model/HousingType'
import {
  NumberFormatter,
} from '@root/plugins/number'

import {
  WizardStepProps,
} from '../EstimationFormWizard'

const CapitalFurnitureInformation = ({
  next,
}: WizardStepProps) => {
  const {
    form: {
      control,
      formState: {
        errors,
      },
      setValue,
      getValues,
    },
    onSubmitHandler, onBackHandler,
  } = useEstimationWizardForm({
    next,
  })

  const [
    housingSurface,
    housingRooms,
    housingType
  ] = getValues(['housing_surface', 'housing_rooms', 'housing_type'])

  const recommended = estimator(parseInt(housingSurface, 10), parseInt(housingRooms, 10), housingType as HousingType)
  const recommendedCapitalFurniture = useMemo(() => {
    return isNaN(recommended)
      ? null
      : {
        label: `${NumberFormatter.format(recommended)} €`,
        value: recommended,
        id: recommended,
      }
  }, [recommended])

  useEffect(() => {
    if (recommendedCapitalFurniture) {
      setValue('capital_furniture', recommendedCapitalFurniture)
    }
  }, [recommendedCapitalFurniture, setValue])

  const {
    t,
  } = useTranslation()

  const description = t('capitalFurniture.information')
  const inputErrors = getErrorMessages({
    ...ErrorMessages,
  }, errors?.capital_furniture?.type)

  return (
    <form onSubmit={onSubmitHandler()} className='mb-8 flex w-full grow flex-col justify-between space-y-6 md:mb-0'>
      <InputGroup
        description={description}
        errors={inputErrors}
      >
        <CapitalFurniture
          control={control}
          error={errors?.capital_furniture?.type}
          label={'Valeur de vos biens mobilier'}
          name='capital_furniture'
        />
      </InputGroup>
      <NavigationButtons onBackHandler={onBackHandler} />
    </form>
  )
}

export default CapitalFurnitureInformation
