import {
  Text, TextVariant,
} from '@selectra-it/selectra-ui'
import {
  FC,
} from 'react'

const Divider: FC = () => {
  return (
    <div className="flex h-[26px] items-center gap-1 ">
      <hr className='flex h-[2px] grow text-neutral-100'/>
      <Text variant={TextVariant.REGULAR}>ou</Text>
      <hr className='flex h-[2px] grow text-neutral-100'/>
    </div>
  )
}

export default Divider
