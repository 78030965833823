import {
  useState,
} from 'react'
import {
  Button, ButtonSize, ButtonType,
} from '@selectra-it/selectra-ui'

import {
  Offer,
} from '@root/domain/Model/Offer'
import {
  tagColors,
  useGetEstimationTags,
} from '@hooks/offers/useGetEstimationTags'
import Icon from '@components/Icon'

import OptionList from './Option/OptionList'
import DocumentList from './Document/DocumentList'
import ProviderInformation from './ProviderInformation'
import SubscriptionButton from './Subscription/SubscriptionButton'
import Details from './OfferListItem/Details'
import GuaranteeList from './OfferListItem/Guarantees/GuaranteeList'
import TagLabel from './OfferListItem/Tags/TagLabel'
import TagContainer from './OfferListItem/Tags/TagContainer'

interface OfferListItemProps {
  offer: Offer,
}
const OfferListItem = ({
  offer,
}: OfferListItemProps) => {
  const [showDetails, setShowDetails] = useState(false)

  const detailsIcon = showDetails ? 'expand less' : 'expand more'
  const buttonLabel = showDetails ? 'Moins d’info' : 'Plus d’info'

  const {
    tag,
  } = useGetEstimationTags(offer.estimationId, offer.id)

  const color = tag ? tagColors[tag].border : ''

  const included = [...offer.packs, ...offer.guarantees].filter(item => item.premium === 0)
  const optional = [...offer.packs, ...offer.guarantees].filter(item => item.premium !== 0)

  return (
    <div className='flex w-full flex-col items-start p-0 last:mb-6 md:last:mb-24'>
      { tag && <TagContainer tag={tag} />}
      <section className={`w-full border-b-2 border-b-neutral-50 bg-white py-6 md:rounded-b-4xl md:rounded-t-2xl ${tag ? `md:border-2 ${color}` : ''}`} role='listitem'>
        <div className='grid grid-cols-1 gap-5 px-4 md:px-6'>

          { tag && <TagLabel tag={tag} />}
          {/* Offer details */}
          <Details offer={offer} />
          <GuaranteeList items={included} franchise={offer.franchise} />

          <div className="flex grow flex-col">
            <div className={`grid overflow-hidden transition-grid-template-rows duration-1000 ${showDetails ? 'mb-5 grid-rows-layout-1' : 'grid-rows-layout-0'}`}>
              <div className={`min-h-0 self-start transition-visibility duration-1000 ${showDetails ? 'visible' : 'invisible'}`}>
                <div className={'grid grid-cols-1 gap-5'}>
                  {/* Options */}
                  { optional.length > 0 && <OptionList items={optional} /> }
                  <ProviderInformation insurerId={offer.insurerId} />
                  {/* Provider info */}

                  {/* Documents */}

                  { offer.documents.length > 0 && <DocumentList documents={offer.documents} /> }
                </div>
              </div>
            </div>

            {/* CTA */}
            <div className='grid grid-cols-2 gap-4'>
              <Button variant={ButtonType.LIGHT} size={ButtonSize.MEDIUM}
                label={buttonLabel}
                hasBorderColor
                pill
                onClick={() => {
                  setShowDetails(!showDetails)
                }}
                iconRight={() => <Icon name={detailsIcon} size={16} />}
              />
              <div>
                <SubscriptionButton offer={offer} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default OfferListItem
