import {
  useTranslation,
} from 'react-i18next'

import {
  tagColors,
} from '@hooks/offers/useGetEstimationTags'

import {
  TagLabelProps,
} from './TagProps'

const TagLabel = ({
  tag,
}: TagLabelProps) => {
  const {
    t,
  } = useTranslation()

  const color = tagColors[tag]

  return (
    <div className={`flex h-8 w-fit flex-col items-start rounded ${color.background} px-4 py-2 md:hidden`}>
      <h3 className='flex items-center text-sm font-bold leading-[16px] text-white'>{t(`offer.tags.${tag}`)}</h3>
    </div>
  )
}

export default TagLabel
