import {
  Combobox,
} from '@selectra-it/selectra-ui'
import {
  forwardRef, useState,
} from 'react'
import {
  LiteralUnion, RegisterOptions,
} from 'react-hook-form'

import useGetInputStatus from '@hooks/useGetInputStatus'
import {
  CallbackDate, getCallbackDateOptions,
} from '@root/domain/Model/OfferMer'

interface CallbackDateInputProps {
  value: CallbackDate | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (...event: any[]) => void,
  name: string,
  error: LiteralUnion<keyof RegisterOptions, string> | undefined;
}

const CallbackDateInput = forwardRef<HTMLInputElement, CallbackDateInputProps>(
  ({
    onChange, value, name, error,
  }, ref) => {
    const [query, setQuery] = useState<string>('')
    const status = useGetInputStatus(error)

    const options = getCallbackDateOptions()

    const displayValue = (item: CallbackDate | null): string => item?.label ?? ''

    const filteredItems =
      query === ''
        ? options
        : (options.filter(item =>
          item.label.toLowerCase().includes(query.toLowerCase())
        ))

    return (
      <Combobox<CallbackDate>
        id='callback-date'
        ref={ref}
        displayValue={displayValue}
        item={value}
        setQuery={setQuery}
        setItem={onChange}
        items={filteredItems}
        label={'Quand'}
        status={status}
        name={name}
        canClearValue={false}
        immediate={true}
      />
    )
  })

CallbackDateInput.displayName = 'CallbackDateInput'

export default CallbackDateInput
