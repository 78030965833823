import {
  Navigate, useLocation,
} from 'react-router-dom'

const Redirect = () => {
  const {
    pathname,
  } = useLocation()

  const route = `/${pathname.split('/').splice(2).join('/')}`

  return (
    <Navigate to={route} replace={true} />
  )
}

export default Redirect
