/**
 * Because JS sucks
 */
export default (variable?: string | boolean | null) => {
  if (!variable) {
    return false
  }

  return (typeof variable === 'boolean') ? variable : variable !== 'false'
}
