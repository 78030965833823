import * as Sentry from '@sentry/react'
import * as Browser from '@sentry/browser'

if (import.meta.env.VITE_ENV === 'production') {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [Browser.browserTracingIntegration()],
    tracesSampleRate: 1.0,
    environment: import.meta.env.MODE,
  })
}
