import {
  MouseEventHandler,
  PropsWithChildren,
  useState,
} from 'react'

import Icon from '@components/Icon'
import Modal from '@components/Modal/Modal'
import {
  Size,
} from '@components/Modal/Size'
import useGetUserFromEstimationIdRouteParam from '@hooks/offers/useGetUserFromEstimationIdRouteParam'
import {
  LeadsourceOption,
} from '@root/domain/Crm/LeadsourceOption'
import {
  PhoneNumbers,
} from '@root/domain/Crm/PhoneNumbers'
import {
  readablePhoneNumber,
} from '@root/util/formatting'

import Callback from './Subscription/Callback/Callback'

interface ButtonProps {
  onClick: MouseEventHandler<HTMLButtonElement>
}

const Button = ({
  children,
  onClick,
}: PropsWithChildren<ButtonProps>) => {
  return (
    <button onClick={onClick} className="flex items-center justify-center gap-2 rounded-3xl bg-white px-16 py-2 md:gap-1 md:px-4 lg:gap-2 lg:px-6">
      {children}
    </button>
  )
}

const CallbackBanner = () => {
  const [isOpen, setIsOpen] = useState(false)
  const {
    fetchUser, estimationId,
  } = useGetUserFromEstimationIdRouteParam()

  const closeModal = () => {
    setIsOpen(false)
  }

  const phoneNumber = PhoneNumbers[LeadsourceOption.COMPARATOR_HELP_CARD]

  return (
    <div className="flex flex-col items-start gap-8 bg-gradient-radial from-[#1460AA] to-[#002D77] p-4 md:rounded-2xl md:px-12 md:py-8">
      <div className="flex flex-col items-start gap-2 text-white">
        <h3 className="text-base font-bold leading-tight md:text-[24px]">Vous n’êtes pas sûr(e) ?</h3>
        <p className="text-left text-sm md:text-base">Nos conseillers comparent les contrats au téléphone et répondent à vos questions gratuitement.</p>
      </div>

      <div className="flex flex-col items-center gap-4 self-stretch md:flex-row">
        <a className="flex items-center justify-center gap-2 rounded-3xl bg-white px-16 py-2 md:gap-1 md:px-4 lg:gap-2 lg:px-6" href={`tel:+33${phoneNumber}`}>
          <Icon name="phone" size={16}
            className='inline-block fill-primary-450'
          />
          <span className='text-base font-semibold leading-tight text-primary-450 md:text-[15px] lg:text-[17px]'>{readablePhoneNumber(phoneNumber)}</span>
        </a>
        <span className="hidden items-center text-center text-[19px] font-semibold leading-tight text-white md:flex">OU</span>
        <Modal
          size={Size.SM}
          isOpen={isOpen}
          onRequestClose={closeModal}
          action={
            <Button onClick={() => setIsOpen(true)}><span className='text-base font-semibold leading-tight text-primary-450 md:text-[15px] lg:text-[17px]'>Rappel gratuit</span>
              <Icon name="chevron right" size={16}
                className='inline-block fill-primary-450'
              /></Button>
          }
        >
          <Callback
            user={fetchUser?.data} estimationId={estimationId}
            leadSourceOption={LeadsourceOption.COMPARATOR_HELP_CARD}
            closeModal={closeModal}
          />
        </Modal>
      </div>
    </div>
  )
}

export default CallbackBanner
