import useEstimationWizardForm from '@hooks/estimation/useEstimationWizardForm'
import UserAge from '@components/form/estimation/UserAge'
import {
  isValidDate,
} from '@root/util/validation/date'
import NavigationButtons from '@components/form/NavigationButtons'

import {
  WizardStepProps,
} from '../EstimationFormWizard'

export interface UserAgeInformationForm {
  birthDate: string,
}

const UserAgeInformation = ({
  next,
}: WizardStepProps) => {
  const {
    form: {
      register, formState: {
        errors,
      },
    },
    onSubmitHandler, onBackHandler,
  } = useEstimationWizardForm({
    mode: 'onChange',
    revalidateMode: 'onChange',
    next,
  })

  return (
    <form onSubmit={onSubmitHandler()} className='flex w-full grow flex-col justify-between space-y-6'>
      <UserAge
        {...register('birthDate', {
          required: true,
          validate: isValidDate,
        })}
        errors={errors.birthDate}
      />

      <NavigationButtons onBackHandler={onBackHandler} />
    </form>
  )
}

export default UserAgeInformation
