import {
  useLocation,
} from 'react-router-dom'

const useIsOfferPage = () => {
  const {
    pathname,
  } = useLocation()

  return pathname.includes('/offre/')
}

export default useIsOfferPage
