const PRINCIPAL = 'principal'

const NON_RESIDING_OWNER = 'non_residing_owner'

const HOUSE = 'house'
const FLAT = 'flat'

export {
  PRINCIPAL,
  NON_RESIDING_OWNER,
  HOUSE,
  FLAT
}
