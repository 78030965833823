import i18n from 'i18next'
import {
  initReactI18next,
} from 'react-i18next'

import fr from '@i18n/fr/index'

export const defaultNS = 'common'
export const resources = {
  fr: {
    common: fr.common,
    wizard: fr.wizard,
    offers: fr.offers,
    validation: fr.validation,
  },
} as const

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // returnNull: false,
    ns: ['common', 'wizard', 'offers'],
    defaultNS: 'common',
    lng: 'fr',
    resources: {
      fr,
    },
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    keySeparator: '.',
    react: {
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'a'],
    },
  })

export default i18n
