import {
  FC,
  useState,
} from 'react'
import {
  Control,
  Controller,
  LiteralUnion,
  RegisterOptions,
} from 'react-hook-form'
import {
  Combobox,
} from '@selectra-it/selectra-ui'

import {
  CapitalFurniture,
  EstimationForm,
} from '@root/domain/Model/Wizard'
import useGetInputStatus from '@hooks/useGetInputStatus'
import {
  NumberFormatter,
} from '@root/plugins/number'

const options: CapitalFurniture[] = Array.from(Array(40).keys(), i => {
  const val = ++i * 5000
  return {
    label: `${NumberFormatter.format(val)} €`,
    value: val,
    id: val,
  }
})

interface CapitalFurnitureProps {
  control: Control<EstimationForm>
  error: LiteralUnion<keyof RegisterOptions, string> | undefined;
  label: string
  name: string,
}

const CapitalFurnitureComponent : FC<CapitalFurnitureProps> = ({
  control,
  error,
  label,
}) => {
  const [query, setQuery] = useState<string>('')
  const status = useGetInputStatus(error)

  const displayValue = (value: CapitalFurniture | null): string => value?.label ?? ''

  const filteredItems = options.filter(item => item.label.toLowerCase().includes(query.toLowerCase()))

  return (
    <Controller
      name={'capital_furniture'}
      control={control}
      rules={{
        required: true,
      }}
      render={renderParams => {
        const {
          field: {
            value,
            ref,
            name,
            onChange,
          },
        } = renderParams

        return (
          <Combobox<CapitalFurniture>
            id='capital_furniture'
            ref={ref}
            anchor={{
              to: 'bottom end',
            }}
            displayValue={displayValue}
            item={value}
            setQuery={setQuery}
            setItem={onChange}
            items={filteredItems}
            label={label}
            status={status}
            name={name}
            readOnly={false}
            canClearValue={true}
            immediate={true}
          />
        )
      }}
    />
  )
}

export default CapitalFurnitureComponent
