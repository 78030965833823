import {
  Button, ButtonSize, ButtonType,
} from '@selectra-it/selectra-ui'
import {
  FC, MouseEventHandler,
} from 'react'

import Icon from '@components/Icon'

interface NavigationButtonsProps {
  onBackHandler: MouseEventHandler<HTMLButtonElement>
  nextLabel?: string;
  disabled?: boolean;
  nextVariant?: ButtonType;
}

const NavigationButtons: FC<NavigationButtonsProps> = ({
  onBackHandler,
  nextLabel,
  disabled,
  nextVariant = ButtonType.PRIMARY,
}) => {
  const submitLabel = nextLabel ?? 'Suivant'

  return (
    <div className='flex gap-5'>
      <Button
        type='button'
        variant={ButtonType.LIGHT}
        size={ButtonSize.LARGE}
        pill
        rounded
        iconLeft={() =>
          <Icon name='arrow back' size={24}
            className='min-w-min fill-[#015AA3]'
          />}
        onClick={onBackHandler}
      />

      <Button
        type='submit'
        disabled={disabled}
        label={submitLabel}
        variant={nextVariant}
        size={ButtonSize.LARGE}
        pill
        dataCy={'submit'}
      />
    </div>
  )
}

export default NavigationButtons
