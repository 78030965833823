import {
  Mode,
  useForm,
} from 'react-hook-form'
import {
  MutableRefObject,
  useCallback,
} from 'react'
import {
  useDispatch,
} from 'react-redux'
import {
  useNavigate,
} from 'react-router-dom'

import {
  RootState, useAppSelector,
} from '@root/store/Reducer'
import {
  getFormState, setFormField,
} from '@root/services/formSlice'
import {
  EstimationForm,
} from '@root/domain/Model/Wizard'
import {
  getRouteInformation,
} from '@root/routes/wizard'

interface useEstimationWizardFormProps {
  mode?: Mode,
  revalidateMode?: Exclude<Mode, 'onTouched' | 'all'>
  next?: (data: EstimationForm, options?: {replace: boolean}) => void
}

const useEstimationWizardForm = ({
  mode,
  revalidateMode,
  next,
}: useEstimationWizardFormProps) => {
  const values = useAppSelector((state: RootState) => getFormState(state))
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const form = useForm<EstimationForm>({
    mode: mode ?? 'onBlur',
    reValidateMode: revalidateMode ?? 'onBlur',
    resolver: undefined,
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    defaultValues: {
      ...values,
    },
  })

  const {
    handleSubmit,
  } = form

  const onSubmitHandler = useCallback(
    (formRef?: MutableRefObject<HTMLFormElement|null>) => handleSubmit(data => {
      /**
       * If the form has only 2 elements, it means that the form is empty
       * While RTK query does not interact with <Suspense> this is a workaround
       */
      if (formRef?.current && formRef.current.elements.length <= 2) {
        return
      }
      dispatch(setFormField(data))
      next?.(data)
    }),
    [handleSubmit, dispatch, next]
  )

  const onBackHandler = useCallback(() => {
    const {
      previous,
    } = getRouteInformation(location.pathname, values, {
      facilityTypes: [],
    })

    previous && navigate(previous?.path)
  }, [navigate, values])

  return {
    form, onSubmitHandler, onBackHandler,
  }
}

export default useEstimationWizardForm
