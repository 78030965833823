import {
  ButtonType,
} from '@selectra-it/selectra-ui'
import {
  FC,
} from 'react'
import {
  useParams,
} from 'react-router-dom'

import {
  useAppSelector,
} from '@root/store/Reducer'
import {
  selectInsurerById,
} from '@root/services/insurers/selectors'
import {
  selectOfferById,
} from '@root/services/offers/selectors'

import {
  MerTypeActionMap,
  MerTypeMap,
} from './v2'
import Divider from './v2/Divider'

const Mer: FC = () => {
  const {
    offerId, estimationId,
  } = useParams()

  const offer = useAppSelector(state => selectOfferById(estimationId as string, offerId as string)(state))
  const insurer = useAppSelector(state => selectInsurerById(state, offer?.insurerId ?? ''))

  if (!offer || !insurer) {
    return null
  }

  const PrimaryMerOption = MerTypeMap[insurer.merGatewayPrimary]
  const SecondaryMerOption = insurer.merGatewaySecondary ? MerTypeMap[insurer.merGatewaySecondary] : null
  const PrimaryMerOptionAction = MerTypeActionMap[insurer.merGatewayPrimary]

  if (!insurer.merGatewaySecondary) {
    return (
      <div className="flex flex-col gap-4  md:p-2">
        <PrimaryMerOptionAction type={ButtonType.PRIMARY} offerId={offer.id}
          estimationId={offer.estimationId}
        />
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-4  md:p-2">
      <PrimaryMerOption type={ButtonType.PRIMARY} offerId={offer.id}
        estimationId={offer.estimationId}
      />

      {SecondaryMerOption && (
        <>
          <Divider />
          <SecondaryMerOption type={ButtonType.SECONDARY} offerId={offer.id}
            estimationId={offer.estimationId}
          />
        </>
      )}
    </div>
  )
}

export default Mer
