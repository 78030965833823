import {
  FC,
} from 'react'
import {
  ButtonType,
} from '@selectra-it/selectra-ui'

import {
  InsurerMerOption,
} from '@root/services/insurers'

import Subscription from './Subscription'
import RequestCallback from './RequestCallback'
import RequestCallbackSelectra from './RequestCallbackSelectra'
import RequestCallbackAction from './actions/RequestCallbackAction'
import RequestCallbackSelectraAction from './actions/RequestCallbackSelectraAction'

export interface MerTypeProps {
  type: ButtonType,
  offerId: string,
  estimationId: string,
}

export const MerTypeMap: Record<InsurerMerOption, FC<MerTypeProps>> = {
  [InsurerMerOption.SUBSCRIPTION]: Subscription,
  [InsurerMerOption.CALLBACK_REQUEST]: RequestCallback,
  [InsurerMerOption.CALLBACK_REQUEST_SELECTRA]: RequestCallbackSelectra,
}

export const MerTypeActionMap: Record<InsurerMerOption, FC<MerTypeProps>> = {
  [InsurerMerOption.SUBSCRIPTION]: Subscription,
  [InsurerMerOption.CALLBACK_REQUEST]: RequestCallbackAction,
  [InsurerMerOption.CALLBACK_REQUEST_SELECTRA]: RequestCallbackSelectraAction,
}
