import {
  Insurer,
} from '@root/domain/Model/Insurer'
import {
  apiService,
} from '@services/apiService'

interface InsurerResponse {
  items: Insurer[];
}

export enum InsurerMerOption {
  SUBSCRIPTION = 'SUBSCRIPTION',
  CALLBACK_REQUEST = 'CALLBACK_REQUEST',
  CALLBACK_REQUEST_SELECTRA = 'CALLBACK_REQUEST_SELECTRA',
}

interface InsurerMerOptionResponse {
  primary: InsurerMerOption,
  secondary?: InsurerMerOption,
}

export const insurerApi = apiService.injectEndpoints({
  endpoints: builder => ({
    getInsurers: builder.query<Insurer[], void>({
      query: () => ({
        url: '/api/insurers',
        params: {
          filters: {
            is_home_insurer: {
              eq: 1,
            },
          },
        },
      }),
      transformResponse: (response: InsurerResponse) => {
        return response.items
      },
    }),
    getInsurerMerOptions: builder.query<InsurerMerOptionResponse, string>({
      query: insurerId => ({
        url: `/api/insurers/${insurerId}/mer-options`,
      }),
    }),
  }),
})

export const {
  useGetInsurersQuery,
  useGetInsurerMerOptionsQuery,
} = insurerApi
