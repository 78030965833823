import {
  FunctionComponent, SVGProps,
} from 'react'

import lovys from '@assets/icons/0eed13ab-f599-4b8a-a6eb-856e4b7c71ad.svg?react'
import acheel from '@assets/icons/21d5f0a5-896b-479c-89a2-49f9d1264892.svg?react'
import klian from '@assets/icons/567e5209-1d26-4d61-9aeb-ffd980c63603.svg?react'
import luko from '@assets/icons/a7566d52-b36b-4b95-ac3a-7491c00aeca6.svg?react'
import leocare from '@assets/icons/c538da14-1485-48c3-91ea-e99229daca29.svg?react'
import acomme from '@assets/icons/fbcbaae9-cdf5-4b5c-97f0-7622bc97b178.svg?react'
import friday from '@assets/icons/23d79e6f-4391-4984-a190-3fa511130ddb.svg?react'
import qivio from '@assets/icons/8d1675b0-cbe6-4246-83d9-11813ee54c6a.svg?react'
import {
  InsurerMerOption,
} from '@root/services/insurers'

import {
  Nullable,
} from './Wizard'

export interface PhoneNumberDetails {
  countryCode: number
  nationalNumber: string
  numberOfLeadingZeros: number
  countryCodeSource: number,
  nationalNumberFormatted: string,
  internationalFormatted: string,
  e164Formatted: string,
}

export interface PhoneNumber {
  countryCode: string
  number: PhoneNumberDetails
}

export enum CustomerService {
  online_chat = 'online_chat',
  phone = 'phone',
}

export type InsurerMerOptionCallbackOnly = Extract<InsurerMerOption, InsurerMerOption.CALLBACK_REQUEST | InsurerMerOption.CALLBACK_REQUEST_SELECTRA>

export type InsurerId = (
  '0eed13ab-f599-4b8a-a6eb-856e4b7c71ad' |
  '21d5f0a5-896b-479c-89a2-49f9d1264892' |
  '567e5209-1d26-4d61-9aeb-ffd980c63603' |
  'a7566d52-b36b-4b95-ac3a-7491c00aeca6' |
  'c538da14-1485-48c3-91ea-e99229daca29' |
  'fbcbaae9-cdf5-4b5c-97f0-7622bc97b178' |
  '23d79e6f-4391-4984-a190-3fa511130ddb' |
  '8d1675b0-cbe6-4246-83d9-11813ee54c6a'
)

export interface Insurer {
  id: InsurerId
  name: string
  description: string
  administrationFee: number
  customerService: CustomerService
  leadSource: string
  phoneNumber: PhoneNumber
  healthOnlineSubscriptionEnabled: boolean
  homeOnlineSubscriptionEnabled: boolean
  oriasNumber: string
  sirenNumber: string
  slug: string
  redistributionRate: number
  managementFees: number
  activeHealthInsurer: boolean
  activeHomeInsurer: boolean
  address: string
  postalCode: string
  locality: string
  isHealthInsurer: boolean
  isHomeInsurer: boolean
  merGatewayPrimary: InsurerMerOption
  merGatewaySecondary: Nullable<InsurerMerOption>
}

export const insurerLogos: Record<InsurerId, FunctionComponent<SVGProps<SVGSVGElement>>> = {
  '0eed13ab-f599-4b8a-a6eb-856e4b7c71ad': lovys,
  '21d5f0a5-896b-479c-89a2-49f9d1264892': acheel,
  '567e5209-1d26-4d61-9aeb-ffd980c63603': klian,
  'a7566d52-b36b-4b95-ac3a-7491c00aeca6': luko,
  'c538da14-1485-48c3-91ea-e99229daca29': leocare,
  'fbcbaae9-cdf5-4b5c-97f0-7622bc97b178': acomme,
  '23d79e6f-4391-4984-a190-3fa511130ddb': friday,
  '8d1675b0-cbe6-4246-83d9-11813ee54c6a': qivio,
}
