import {
  useNavigate,
} from 'react-router-dom'

import Icon from '@components/Icon'
import {
  displayRating,
} from '@root/util/rating'

interface RatingModalTriggerProps {
  rating: number,
  offerId: string
}

const RatingModalTrigger = ({
  rating,
  offerId,
}: RatingModalTriggerProps) => {
  const getColor = (rating: number): string => {
    if (rating < 3) {
      return 'fill-neutral-350'
    }

    if (rating < 3.5) {
      return 'fill-primary-400'
    }

    return 'fill-success-400'
  }

  const navigate = useNavigate()

  const onClick = () => {
    navigate(`${offerId}/rating`)
  }

  return (
    <div className='flex items-center gap-2'>

      <h3 className='flex items-center gap-2 text-sm text-neutral-400 md:text-base'>
        <Icon name="verified-user" size={20}
          className={`${getColor(rating / 100 * 5)}`}
        />
        Score Selectra: <span className='font-bold'>{displayRating(rating)}/5</span>
        <div onClick={onClick} className='flex items-center'>
          <Icon name='help-circle' size={16}
            className='inline-block cursor-pointer fill-neutral-250'
          />
        </div>
      </h3>
    </div>
  )
}

export default RatingModalTrigger
