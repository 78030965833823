import {
  createSelector,
} from '@reduxjs/toolkit'

import {
  RootState,
} from '@root/store/Reducer'

import {
  GetOffersQuery,
  OffersApi,
} from './index'

const selectOffersByEstimationIdBase = OffersApi.endpoints.getOffersByEstimationId.select

export const selectOffersForEstimationId = (params: GetOffersQuery) =>
  selectOffersByEstimationIdBase(params)

export const selectOfferById = (estimationId: string, offerId: string) =>
  createSelector(
    (state: RootState) => {
      return selectOffersForEstimationId({
        estimationId,
        field: state.offerSort.field,
        direction: state.offerSort.direction,
      })(state as never)
    },
    offers => {
      return offers.data?.items.find(offer => offer.id === offerId) || null
    }
  )
