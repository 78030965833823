import Icon from '@components/Icon'

interface CoverageIconProps {
  icon: string
}

const CoverageIcon = ({
  icon,
}: CoverageIconProps) => {
  return (
    <Icon name={icon} size={24}
      className='fill-neutral-300'
    />
  )
}

export default CoverageIcon
