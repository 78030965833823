import {
  FC,
} from 'react'
import {
  Caption, TextVariant,
} from '@selectra-it/selectra-ui'

import {
  selectInsurerById,
} from '@root/services/insurers/selectors'
import {
  useAppSelector,
} from '@root/store/Reducer'

interface OfferProviderProps {
  providerId: string
}

const OfferProvider: FC<OfferProviderProps> = ({
  providerId,
}) => {
  const provider = useAppSelector(state => selectInsurerById(state, providerId))

  if (!provider) {
    return null
  }

  return (
    <Caption variant={TextVariant.REGULAR}>
      Risque porté par {provider.name}
    </Caption>
  )
}

export default OfferProvider
