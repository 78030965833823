import {
  ResidenceType,
} from '@root/domain/Model/Estimation'
import {
  apiService,
} from '@services/apiService'

interface HousingTypeItem {
  label: string,
  icon?: string,
  value: ResidenceType,
}

interface ResidenceTypesResponse {
  items: ResidenceType[]
}

const residenceTypeLabel = (item: ResidenceType) => {
  return item === 'principal' ? 'Oui' : 'Non'
}

export const insurerApi = apiService.injectEndpoints({
  endpoints: builder => ({
    getResidenceTypes: builder.query<HousingTypeItem[], void>({
      query: () => 'api/home/residence-types',
      transformResponse: (response: ResidenceTypesResponse) => {
        return response.items.map(item => ({
          label: residenceTypeLabel(item),
          value: item,
        }))
      },
    }),
  }),
})

export const {
  useGetResidenceTypesQuery,
} = insurerApi
