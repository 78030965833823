import {
  useTranslation,
} from 'react-i18next'

import {
  FrenchNumberFormmater,
} from '@root/plugins/number'

interface OptionListItemPriceProps {
  price: number,
}

const OptionListItemPrice = ({
  price,
}: OptionListItemPriceProps) => {
  const {
    t,
  } = useTranslation(['common'])
  const montlhyCost = FrenchNumberFormmater.format(price / 12 / 100)

  const label = price < 0
    ? 'Activable'
    : price === 0 ? 'Incluse' : `à +${montlhyCost}€/${t('common:month')}`

  return (
    <div className='flex items-baseline rounded-[4px] bg-neutral-50 px-2 py-[2px]'>
      <span className="text-sm font-bold text-neutral-450">{label}</span>
    </div>
  )
}

export default OptionListItemPrice
