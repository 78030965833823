interface DocumentLegendItemProps {
  color: string,
  percentage: number,
  label: string
}

const DocumentLegendItem = ({
  color,
  percentage,
  label,
}: DocumentLegendItemProps) => {
  return (
    <div>
      <h3 className={`${color} mr-2 inline-block size-[10px] rounded-full text-base font-bold`} />
      <span className='mr-1 text-base font-bold text-neutral-450'>{percentage}%</span>
      <span className='text-base text-neutral-450'>{label}</span>
    </div>
  )
}

export default DocumentLegendItem
