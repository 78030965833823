import {
  apiService,
} from '@services/apiService'

interface Label {
  text: string
  gender: string
}

export interface FacilityType {
  value: string
  icon: string
  label: Label
  withSurface: boolean
}

interface FacilityTypeItem {
  id: string
  key: string
  label: Label
  withSurface: boolean
}

interface FacilityTypesResponse {
  items: FacilityTypeItem[]
}

export const insurerApi = apiService.injectEndpoints({
  endpoints: builder => ({
    getFacilityTypes: builder.query<FacilityType[], void>({
      query: () => 'api/home/facilities',
      transformResponse: (response: FacilityTypesResponse) => {
        return response.items.map(item => ({
          label: item.label,
          icon: item.key,
          value: item.id,
          withSurface: item.withSurface,
        }))
      },
    }),
  }),
})

export const {
  useGetFacilityTypesQuery,
} = insurerApi
