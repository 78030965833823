import {
  Guarantee, Pack,
} from '@root/domain/Model/Offer'

import OptionListItemPrice from './OptionListItemPrice'
import OptionListItemCoverage from './OptionListItemCoverage'

interface OptionListItemProps {
  item: Guarantee | Pack
}

const OptionListItem = ({
  item,
}: OptionListItemProps) => {
  const isPack = !('coverage' in item)
  const {
    premium,
  } = item

  return (
    <div className='box-border flex items-start gap-2 self-stretch rounded-xl border border-dashed border-neutral-150 p-2 text-sm md:text-base'>
      <div className='flex grow items-baseline gap-[2px] self-stretch p-0'>
        <div className='grow'>
          <h6 className='flex flex-col gap-2 text-neutral-350'>

            { !isPack && <OptionListItemCoverage coverage={item.coverage} /> }
            { isPack && item.coverages.map(item => <OptionListItemCoverage coverage={item} key={item.id} />)}
          </h6>
        </div>
      </div>
      <div>
        <OptionListItemPrice price={premium} />
      </div>
    </div>
  )
}

export default OptionListItem
