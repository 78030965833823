import {
  useSelector,
} from 'react-redux'

import {
  calculateFinalPrice,
  Offer,
} from '@root/domain/Model/Offer'
import {
  InsurerId,
  insurerLogos,
} from '@root/domain/Model/Insurer'
import {
  useGetInsurersQuery,
} from '@root/services/insurers'
import {
  RootState,
} from '@root/store/Reducer'
import {
  selectInsurerById,
} from '@root/services/insurers/selectors'

import OfferProvider from '../OfferProvider'
import RatingModalTrigger from '../RatingModal/RatingModalTrigger'

import Price from './Price/Price'

interface DetailsProps {
  offer: Offer
}

const Details = ({
  offer,
}: DetailsProps) => {
  const {
    insurerId,
    monthlyPrice,
    rating,
    title,
    providerId,
    id,
  } = offer

  useGetInsurersQuery()
  const insurer = useSelector((state: RootState) => {
    return selectInsurerById(state, insurerId)
  })

  const finalPrice = calculateFinalPrice(insurer, monthlyPrice)

  const Icon = insurerLogos[insurerId as InsurerId]

  return (
    <div className='flex w-full grow'>
      <div className='grid grow gap-2'>
        <div className="h-[30px] w-[100px]">
          <Icon height={30}/>
        </div>
        <div className='flex items-center'>
          <h3 className='text-base font-bold text-neutral-500 md:text-lg'>{ title }</h3>
        </div>
        {providerId && <OfferProvider providerId={providerId} />}
        <RatingModalTrigger rating={rating} offerId={id} />
      </div>
      <div className='text-right'>
        <Price price={finalPrice} />
      </div>
    </div>
  )
}

export default Details
