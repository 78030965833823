import {
  INPUT_STATUS,
} from '@selectra-it/selectra-ui'
import {
  LiteralUnion, RegisterOptions,
} from 'react-hook-form'

type useGetInputStatusProps = LiteralUnion<keyof RegisterOptions, string> | undefined

const useGetInputStatus = (error: useGetInputStatusProps) => {
  if (error) { return INPUT_STATUS.DANGER }

  return INPUT_STATUS.DEFAULT
}

export default useGetInputStatus
