interface CalculatedItem {
  start: number,
  end: number,
  color: string,
}

interface Item {
  color: string,
  value: number
}

interface DonutProps {
  data: Item[]
}

const calculatePercetages = (data: Item[]) => {
  const total = data.reduce((acc, curr) => {
    acc += curr.value
    return acc
  }, 0)

  const items = data.reduce<CalculatedItem[]>((acc, item, index) => {
    const start = acc[index - 1]?.end ?? 0
    const end = (item.value * 360 / total) + start

    acc.push({
      start,
      end,
      color: item.color,
    })

    return acc
  }, [])
    .map(item => `${item.color} ${item.start}deg ${item.end}deg`)

  return items.join(',')
}

const Donut = ({
  data,
}: DonutProps) => {
  const cssString = calculatePercetages(data)

  return (
    <svg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'
      className='h-full rounded-full'
    >
      <clipPath id='hole'>
        <path d='M 50 0 a 50 50 0 0 1 0 100 50 50 0 0 1 0 -100 v 18 a 2 2 0 0 0 0 64 2 2 0 0 0 0 -64' />
      </clipPath>
      <text x="50%" y="50%"
        stroke="" strokeWidth="2px"
        textAnchor="middle"
        alignmentBaseline="middle"
        className="font-bold"
      > 100%</text>

      <foreignObject x='0' y='0'
        width='100' height='100'
        clipPath='url(#hole)'
      >
        <div
          className='size-full'
          // styles={{
          //   background: 'conic-gradient(red 36deg, orange 36deg 170deg, yellow 170deg)',
          // }}
          style={{
            background: `conic-gradient(${cssString})`,
          }}
        />
      </foreignObject>
    </svg>
  )
}

export default Donut
