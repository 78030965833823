import PropTypes from 'prop-types'
import {
  useEffect, useRef, useState,
} from 'react'

import withMaps from '@root/hoc/withMaps'

import {
  mapConfig,
} from './mapConfig'

const parser = new DOMParser()

// A marker with a custom inline SVG.
const pinSvgString = '<svg width="32" height="32" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M25 -1H24V0H25V-1ZM9 8V13H11V10H13V13H15V8L12 6L9 8ZM16.525 14.262C15.5417 15.7873 14.0333 17.4833 12 19.35C9.96667 17.4833 8.45833 15.7873 7.475 14.262C6.49167 12.7373 6 11.3833 6 10.2C6 8.38333 6.579 6.89567 7.737 5.737C8.89567 4.579 10.3167 4 12 4C13.6833 4 15.104 4.579 16.262 5.737C17.4207 6.89567 18 8.38333 18 10.2C18 11.3833 17.5083 12.7373 16.525 14.262ZM5.988 15.637C7.31267 17.5957 9.31667 19.7167 12 22C14.6833 19.7167 16.6877 17.5957 18.013 15.637C19.3377 13.679 20 11.8667 20 10.2C20 7.7 19.1957 5.70833 17.587 4.225C15.979 2.74167 14.1167 2 12 2C9.88333 2 8.021 2.74167 6.413 4.225C4.80433 5.70833 4 7.7 4 10.2C4 11.8667 4.66267 13.679 5.988 15.637Z" fill="rgb(1, 90, 163)"></path></svg>'
const pinSvgElement =
    parser.parseFromString(pinSvgString, 'image/svg+xml').documentElement

const Map = props => {
  const {
    geometry,
    zoom,
  } = props

  const [map, setMap] = useState(null)
  const [, setMarker] = useState(null)
  const mapRef = useRef()

  useEffect(() => {
    if (mapRef.current && geometry?.location) {
      const styledMapType = new window.google.maps.StyledMapType(mapConfig, {
        name: 'Styled Map',
      })

      const map = new window.google.maps.Map(mapRef.current, {
        center: geometry.location,
        zoom,
        disableDefaultUI: true,
        gestureHandling: 'none',
        mapId: '8e0a97af9386fef',
      })

      map.mapTypes.set('styled_map', styledMapType)
      map.setMapTypeId('styled_map')
      setMap(map)
    }
  }, [geometry, zoom])

  useEffect(() => {
    if (map && geometry) {
      map.fitBounds(geometry.viewport)

      setMarker(
        new window.google.maps.marker.AdvancedMarkerElement({
          position: geometry.location,
          map,
          title: 'Hello World!',
          content: pinSvgElement,
        })
      )
    }
  }, [geometry, map])

  return (
    <>
      <div ref={mapRef} id="map"
        className='aspect-golden rounded-lg border-2 border-neutral-150'
      />
    </>
  )
}

Map.propTypes = {
  geometry: PropTypes.shape({
    location: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
    viewport: PropTypes.shape({
      south: PropTypes.number,
      west: PropTypes.number,
      north: PropTypes.number,
      east: PropTypes.number,
    }),
  }),
  zoom: PropTypes.number,
}

export default withMaps(Map)
