import {
  apiService,
} from '@services/apiService'

interface FamilySituation {
  value: string,
  label: string,
}

interface Item {
  key: string
  label: string
}

interface FamilySituationResponse {
  items: Item[]
}

export const insurerApi = apiService.injectEndpoints({
  endpoints: builder => ({
    getFamilitySitutationTypes: builder.query<FamilySituation[], void>({
      query: () => '/api/home/family-situation',
      transformResponse: (response: FamilySituationResponse) => response.items.map(item => ({
        label: item.label,
        value: item.key,
      })),
      keepUnusedDataFor: 60 * 10,
    }),
  }),
})

export const {
  useGetFamilitySitutationTypesQuery,
} = insurerApi
