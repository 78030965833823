import {
  isRejectedWithValue,
} from '@reduxjs/toolkit'

import type {
  Middleware,
} from '@reduxjs/toolkit'

export class ValidationError<T> extends Error {
  public errors

  constructor (
    message: string,
    errors: {
      [K in keyof T]: string[];
    }
  ) {
    super(message)

    // Set the prototype explicitly.
    this.errors = errors
    Object.setPrototypeOf(this, ValidationError.prototype)
  }
}

/**
 * Log a warning and show a toast!
 */
export const ValidationExceptionMiddleware: Middleware =
  () => next => action => {
    if (isRejectedWithValue(action)) {
      if (action?.payload?.status === 422) {
        throw new ValidationError(action.payload.data.message, action.payload.data.errors)
      }
    }

    return next(action)
  }
