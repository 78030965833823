import {
  useEffect,
} from 'react'

import {
  MerStatus, useFetchMerQuery,
} from '@root/services/offers'

const useGetCompletedMer = ({
  offerId,
  id,
  isMerCreateSuccess,
}: {
  offerId: string,
  id: string,
  isMerCreateSuccess: boolean
}) => {
  const {
    data,
    refetch,
    isLoading,
    isSuccess,
  } = useFetchMerQuery({
    offerId,
    merId: id,
  }, {
    skip: !isMerCreateSuccess,
  })

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>

    if (data?.status !== MerStatus.DONE && data?.status !== MerStatus.ERROR && isMerCreateSuccess) {
      interval = setInterval(() => {
        refetch()
      }, 1200)
    }

    return () => {
      clearInterval(interval)
    }
  }, [refetch, isMerCreateSuccess, data?.status])

  return {
    data,
    isLoading,
    isSuccess,
    isFinished: isSuccess && data.status === MerStatus.DONE,
    inProgress: isSuccess && data.status !== MerStatus.DONE,
  }
}

export default useGetCompletedMer
