import {
  useTranslation,
} from 'react-i18next'
import {
  InputGroup, getErrorMessages, ErrorMessages, TextInput,
} from '@selectra-it/selectra-ui'

import useEstimationWizardForm from '@hooks/estimation/useEstimationWizardForm'
import useGetInputStatus from '@hooks/useGetInputStatus'
import NavigationButtons from '@components/form/NavigationButtons'

import {
  WizardStepProps,
} from '../EstimationFormWizard'

export interface HouseSurfaceInformationForm {
  housing_surface: number,
}

const HouseSurfaceInformation = ({
  next,
}: WizardStepProps) => {
  const {
    form: {
      register, formState: {
        errors,
      },
    },
    onSubmitHandler, onBackHandler,
  } = useEstimationWizardForm({
    next,
  })

  const {
    t,
  } = useTranslation()

  const status = useGetInputStatus(errors?.housing_surface?.type)
  const inputErrors = getErrorMessages({
    ...ErrorMessages,
    required: 'J’ai besoin de la surface habitable de votre logement afin d’établir vos devis.',
    min: t('validation.surface'),
    max: t('validation.surface'),
  }, errors?.housing_surface?.type)

  return (
    <form onSubmit={onSubmitHandler()} className='flex w-full grow flex-col justify-between space-y-6'>
      <InputGroup
        errors={inputErrors}
      >
        <TextInput
          label='Surface habitable en m2'
          status={status}
          {...register('housing_surface', {
            required: true,
            min: 1,
            max: 300,
          })}
          type="number"
        />

      </InputGroup>

      <NavigationButtons onBackHandler={onBackHandler} />
    </form>
  )
}

export default HouseSurfaceInformation
