import {
  ChangeEvent,
  forwardRef,
} from 'react'
import {
  FieldError,
} from 'react-hook-form'
import {
  DATE_MASK,
  MaskedInput,
  InputGroup,
  INPUT_STATUS,
  getErrorMessages,
  ErrorMessages,
} from '@selectra-it/selectra-ui'

interface UserAgeProps {
  name: string,
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  onBlur: (e: ChangeEvent<HTMLInputElement>) => void
  errors?: FieldError,
  errorMessages?: Record<string, string>
}

const useInputStatus = (errors?: FieldError) => {
  if (!errors) {
    return INPUT_STATUS.DEFAULT
  }

  return INPUT_STATUS.DANGER
}

const UserAge = forwardRef<HTMLInputElement, UserAgeProps>((props, ref) => {
  const {
    name, onChange, onBlur, errors,
  } = props

  const status = useInputStatus(errors)

  return (
    <InputGroup description='Ex : 24/02/1993' errors={getErrorMessages({
      ...ErrorMessages, validate: 'Merci de saisir sous format JJ/MM/AAAA (ex: 16/02/1998)',
    }, errors?.type)}
    >
      <MaskedInput
        label='Date de naissance'
        ref={ref}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        mask={DATE_MASK}
        status={status}
      />
    </InputGroup>
  )
})

UserAge.displayName = 'UserAge'

export default UserAge
