import PropTypes from 'prop-types'

import FacilitiesSurface from '@components/form/estimation/FacilitiesSurface'
import useEstimationWizardForm, {
} from '@hooks/estimation/useEstimationWizardForm'
import NavigationButtons from '@components/form/NavigationButtons'

const FacilitiesSurfaceInformation = ({
  next,
}) => {
  const {
    form: {
      control, register, watch, formState: {
        errors,
      },
    },
    onSubmitHandler, onBackHandler,
  } = useEstimationWizardForm({
    next,
  })

  const facilities = watch('facilities')

  return (
    <form onSubmit={onSubmitHandler()} className='flex w-full grow flex-col justify-between space-y-6'>
      <div className='grid grid-cols-1 gap-6'>
        <FacilitiesSurface
          facilities={facilities}
          register={register}
          errors={errors.facilitiesSurface}
          next={next}
          control={control}
          name='facilities'
        />
      </div>

      <NavigationButtons onBackHandler={onBackHandler} />
    </form>
  )
}

FacilitiesSurfaceInformation.propTypes = {
  next: PropTypes.func.isRequired,
}

export default FacilitiesSurfaceInformation
