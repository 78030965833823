import {
  FC,
} from 'react'
import {
  useTranslation,
} from 'react-i18next'
import {
  useParams,
} from 'react-router-dom'
import {
  Button, ButtonSize, ButtonType,
} from '@selectra-it/selectra-ui'

import ArrowRight from '@assets/icons/arrow-right.svg?react'
import {
  useAppSelector,
} from '@root/store/Reducer'
import {
  selectOfferById,
} from '@root/services/offers/selectors'
import {
  selectInsurerById,
} from '@root/services/insurers/selectors'
import {
  useFetchMerQuery,
} from '@root/services/offers'
import Icon from '@components/Icon'

const SubscriptionSuccess: FC = () => {
  const {
    offerId, estimationId, merId,
  } = useParams()
  const offer = useAppSelector(state => selectOfferById(estimationId as string, offerId as string)(state))
  const insurer = useAppSelector(state => selectInsurerById(state, offer?.insurerId ?? ''))
  const {
    data,
  } = useFetchMerQuery({
    merId: merId as string,
    offerId: offerId as string,
  })

  const {
    t,
  } = useTranslation(['offers'])

  if (!data || !insurer) {
    return null
  }

  return (
    <div className='flex flex-col items-center text-wrap text-center'>
      <Icon name='circle-check' size={80}
        className='fill-success-400'
      />
      <div>
        <p className='mb-4 mt-6 justify-normal text-base font-semibold md:text-lg'>{t('offers:subscription.success.callback', {
          insurerName: insurer.name,
        })}</p>
      </div>

      <Button variant={ButtonType.SUCCESS} label='Finaliser ma souscription'
        size={ButtonSize.MEDIUM}
        iconRight={() => <ArrowRight />}
        onClick={() => {
          window.open(data.url, '_blank')
        }}
      />
    </div>
  )
}

export default SubscriptionSuccess
