import {
  useTranslation,
} from 'react-i18next'

import {
  tagColors,
} from '@hooks/offers/useGetEstimationTags'

import {
  TagLabelProps,
} from './TagProps'

const TagContainer = ({
  tag,
}: TagLabelProps) => {
  const {
    t,
  } = useTranslation()

  const color = tagColors[tag]

  return (
    <div className={`mx-0 -mb-4 hidden flex-col items-start rounded-r-lg rounded-t-2xl pb-6 pl-6 pr-4 pt-2 md:flex ${color.background}`}>
      <h3 className='flex items-center text-sm font-bold leading-[16px] text-white'>{t(`offer.tags.${tag}`)}</h3>
    </div>
  )
}

export default TagContainer
