import {
  FC,
} from 'react'
import {
  Subheading, SubheadingType,
} from '@selectra-it/selectra-ui'
import {
  useParams,
} from 'react-router-dom'

import {
  insurerLogos,
} from '@root/domain/Model/Insurer'
import {
  useAppSelector,
} from '@root/store/Reducer'
import {
  selectOfferById,
} from '@root/services/offers/selectors'
import {
  selectInsurerById,
} from '@root/services/insurers/selectors'
import {
  calculateFinalPrice,
} from '@root/domain/Model/Offer'

import Price from '../OfferListItem/Price/Price'
import OfferProvider from '../OfferProvider'

const MerHeader: FC = () => {
  const {
    offerId, estimationId,
  } = useParams()

  const offer = useAppSelector(state => selectOfferById(estimationId as string, offerId as string)(state))
  const insurer = useAppSelector(state => selectInsurerById(state, offer?.insurerId ?? ''))

  if (!offer || !insurer) {
    return null
  }

  const {
    providerId,
  } = offer

  const Icon = insurerLogos[insurer.id]

  const price = calculateFinalPrice(insurer, offer.monthlyPrice)

  return (
    <div className="flex gap-2">
      <div className="flex grow flex-col gap-2">
        <div>
          <Icon height={40} />
        </div>
        <Subheading type={SubheadingType.TERTIARY}>
          {offer.title}
        </Subheading>
        {providerId && <OfferProvider providerId={providerId} />}
      </div>
      <Price price={price} />
    </div>
  )
}

export default MerHeader
