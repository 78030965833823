import {
  ReactNode, useEffect, useState,
} from 'react'
import {
  useTransition,
  animated,
} from '@react-spring/web'

interface VerticalCarrouselProps {
  duration?: number,
  delay?: number,
  children: ReactNode[]
  onIterationCompleted?: () => void
}

const VerticalCarrousel = ({
  duration = 400,
  delay = 3200,
  children,
  onIterationCompleted,
}: VerticalCarrouselProps) => {
  const [index, setIndex] = useState<number>(0)
  const [iteration, setIteration] = useState<number>(0)

  const transitions = useTransition(index, {
    key: index,
    from: {
      opacity: 0,
      transform: 'translateY(100%)',
    },
    enter: {
      opacity: 1,
      transform: 'translateY(0%)',
    },
    leave: {
      opacity: 0,
      transform: 'translateY(-100%)',
      delay,
    },
    config: {
      duration,
    },
    onRest: (_a, _b, item) => {
      if (index === item) {
        setIteration(state => state + 1)
        setIndex(state => (state + 1) % children.length)
      }
    },
    exitBeforeEnter: true,
  })

  useEffect(() => {
    if (iteration === children.length + 1) {
      onIterationCompleted?.()
    }
  }, [iteration, children, onIterationCompleted])

  return transitions((style, item) => {
    return <animated.div style={style}>{children[item]}</animated.div>
  })
}

export default VerticalCarrousel
