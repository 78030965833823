import {
  FC,
} from 'react'
import {
  FieldError, UseFormRegister,
} from 'react-hook-form'
import {
  ErrorMessages, InputGroup, RADIO_STATUS, RadioBox, RadioBoxContainer, getErrorMessages,
} from '@selectra-it/selectra-ui'

import {
  useGetResidenceTypesQuery,
} from '@root/services/residenceTypes'
import {
  EstimationForm,
} from '@root/domain/Model/Wizard'

interface ResidenceTypeProps {
  errors?: FieldError,
  register: UseFormRegister<EstimationForm>,
  onAnimationFinished: () => void,
}

const ResidenceType: FC<ResidenceTypeProps> = ({
  register,
  errors,
  onAnimationFinished,
}) => {
  const {
    data, error, isLoading,
  } = useGetResidenceTypesQuery()

  if (isLoading || error || !data?.length) {
    return (
      <div />
    )
  }

  return (
    <InputGroup errors={getErrorMessages(ErrorMessages, errors?.type)}>
      <RadioBoxContainer>
        {data.map(item => (
          <RadioBox key={item.value}
            animate={true}
            onAnimationFinished={onAnimationFinished}
            id={item.value}
            {...register('residence_type', {
              required: true,
            })}
            item={{
              icon: () => <></>,
              value: item.value,
              label: item.label,
            }}
            status={errors ? RADIO_STATUS.DANGER : RADIO_STATUS.DEFAULT}
          />
        ))}
      </RadioBoxContainer>
    </InputGroup>
  )
}

export default ResidenceType
