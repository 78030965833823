import {
  useSelector,
} from 'react-redux'
import {
  useTranslation,
} from 'react-i18next'

import {
  useGetInsurersQuery,
} from '@root/services/insurers'
import {
  selectInsurerById,
} from '@root/services/insurers/selectors'
import {
  RootState,
} from '@root/store/Reducer'
import Icon from '@components/Icon'

import CustomerServiceIcon from './OfferListItem/CustomerServiceIcon'

interface ProviderInformationProps {
  insurerId: string
}

const ProviderInformation = ({
  insurerId,
}: ProviderInformationProps) => {
  useGetInsurersQuery()

  const insurer = useSelector((state: RootState) => selectInsurerById(state, insurerId))
  const {
    t,
  } = useTranslation()

  if (!insurer) {
    return (
      <></>
    )
  }

  const {
    name,
    description,
    administrationFee,
    customerService,
  } = insurer

  return (
    <>
      <div className='flex flex-col gap-2 md:gap-4'>
        <h3 className='text-left text-base font-bold md:text-lg'>A propos de {name}</h3>
        <ul className='list-inside list-disc text-left text-sm text-neutral-400 md:text-base'>
          {description.split(' -').map(item => item.replace('-', '').trim()).map(item => <li className="before:-ml-2 before:content-['']" key={item}>{item}</li>)}
        </ul>
      </div>

      <div>
        <div className='grid grid-cols-2 gap-1'>
          <div className='flex flex-col gap-2 md:gap-4'>
            <h3 className='text-left text-base font-bold md:text-lg'>Frais de dossier</h3>
            <div className='flex items-start gap-1 text-left text-sm text-neutral-400'>
              { administrationFee === 0 &&
              <Icon name='block' className='inline-block fill-success-300'
                size={20}
              />}

              { administrationFee > 0 &&
              <Icon name='database' className='inline-block fill-success-300'
                size={20}
              />}
              <p className='text-sm md:text-base'>
                {t('application_fee', {
                  count: administrationFee,
                })}
              </p>
            </div>
          </div>
          <div className='flex flex-col gap-2 md:gap-4'>
            <h3 className='text-left text-base font-bold md:text-lg'>Service client</h3>
            <div className='flex items-start gap-1 text-justify text-sm text-neutral-400'>
              <CustomerServiceIcon type={customerService} />
              <p className='text-sm md:text-base'>
                {t(`customer_service.${customerService}`)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProviderInformation
