import {
  useTranslation,
} from 'react-i18next'
import {
  FC,
} from 'react'
import {
  useParams,
} from 'react-router-dom'

import useGetUserFromEstimationIdRouteParam from '@hooks/offers/useGetUserFromEstimationIdRouteParam'
import {
  useAppSelector,
} from '@root/store/Reducer'
import {
  selectOfferById,
} from '@root/services/offers/selectors'
import {
  selectInsurerById,
} from '@root/services/insurers/selectors'
import Icon from '@components/Icon'
import {
  useFetchMerQuery,
} from '@root/services/offers'
import {
  InsurerMerOption,
} from '@root/services/insurers'

const CallbackSuccess: FC = () => {
  const {
    fetchUser: {
      data: user,
    },
    estimationId,
  } = useGetUserFromEstimationIdRouteParam()

  const {
    offerId, merId,
  } = useParams()

  const {
    data,
  } = useFetchMerQuery({
    merId: merId as string,
    offerId: offerId as string,
  })

  const {
    t,
  } = useTranslation(['offers'])

  const offer = useAppSelector(state => selectOfferById(estimationId as string, offerId as string)(state))
  const insurer = useAppSelector(state => selectInsurerById(state, offer?.insurerId ?? ''))

  if (!user || !insurer || !data) {
    return null
  }

  const message = data.type === InsurerMerOption.CALLBACK_REQUEST
    ? t('offers:subscription.success.callback', {
      insurerName: insurer.name,
    })
    : t('offers:subscription.success.callback_selectra')

  return (
    <div className='flex flex-col items-center text-wrap text-center'>
      <Icon name='circle-check' size={80} />
      <div>
        <p className='mb-4 mt-6 justify-normal text-lg font-semibold text-success-400 md:text-2xl'>{message}</p>
      </div>
      <p className='mb-4 text-neutral-300 md:text-lg'>
        <span className='font-semibold'>Vous serez rappelé dans les plus brefs délais au {user.phoneNumberNational}</span></p>
    </div>
  )
}

export default CallbackSuccess
