import {
  useDispatch,
} from 'react-redux'

import {
  Guarantee, Pack,
} from '@root/domain/Model/Offer'
import {
  toggleGuaranteesModal,
} from '@root/services/modalSlice'

import Franchise from '../Franchise/Franchise'

import GuaranteeListItem from './GuaranteeListItem'

interface GuaranteeListProps {
  items: (Pack|Guarantee)[],
  franchise: number,
}

const GuaranteeList = ({
  items,
  franchise,
}: GuaranteeListProps) => {
  const dispatch = useDispatch()

  return (
    <div className='flex flex-col items-start gap-2 md:gap-4'>
      <div className='flex items-start justify-between gap-3 self-stretch'>
        <h3 className='text-base font-bold text-neutral-500 md:text-lg'>Garanties incluses</h3>
        <Franchise franchise={franchise} />
      </div>

      <div className='flex flex-col items-start gap-2'>
        <GuaranteeListItem item='Garanties obligatoires (6)' onClick={() => {
          dispatch(toggleGuaranteesModal())
        }}
        />
        {items.map(item => {
          return <GuaranteeListItem item={item} key={item.id} />
        })}
      </div>
    </div>
  )
}

export default GuaranteeList
