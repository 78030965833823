import {
  apiService,
} from '@services/apiService'

interface AmountOfClaims {
  key: string,
  label: string,
}

interface AmountOfClaimsItem {
  value: string,
  label: string,
}

interface AmountOfClaimsResponse {
  items: AmountOfClaims[];
}

export const insurerApi = apiService.injectEndpoints({
  endpoints: builder => ({
    getAmountOfAccidentTypes: builder.query<AmountOfClaimsItem[], void>({
      query: () => 'api/home/amount-of-claims',
      transformResponse: (response: AmountOfClaimsResponse) => response.items.map(item => ({
        label: item.label,
        value: item.key,
      })),
      keepUnusedDataFor: 60 * 10,
    }),
  }),
})

export const {
  useGetAmountOfAccidentTypesQuery,
} = insurerApi
