export default {
  wizard: {
    steps: {
      startDate: {
        label: 'Autre date',
        description: 'Ex : 31/01/2024',
        today: 'Aujourd\'hui',
        tomorrow: 'Demain',
      },
      name: {
        firstName: {
          label: 'Prénom',
        },
        lastName: {
          label: 'Nom',
        },
      },
      phoneNumber: {
        label: 'Numéro de téléphone',
        description: 'Ex : 06 86 52 04 12',
      },
    },
  },
}
