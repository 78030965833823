import {
  Wrapper,
} from '@googlemaps/react-wrapper'
import {
  forwardRef,
} from 'react'

const withMaps = WrappedComponent => {
  const withMapsComponent = (props, ref) => {
    return (
      <Wrapper apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}
        libraries={[
          'places',
          'marker'
        ]}

      >
        <WrappedComponent {...props} ref={ref} />
      </Wrapper>
    )
  }

  return forwardRef(withMapsComponent)
}

export default withMaps
