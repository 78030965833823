import {
  string,
} from 'prop-types'

import {
  useGetCoverageTypesQuery,
} from '@root/services/coverages'

interface CoverageNameProps {
  name: string
}

const CoverageName = ({
  name,
}: CoverageNameProps) => {
  const {
    data,
  } = useGetCoverageTypesQuery()

  if (!data) {
    return (<></>)
  }

  const item = data.find(item => item.id === name)

  return (
    <span className="block text-neutral-400">{item ? item.label : name}</span>
  )
}

CoverageName.propTypes = {
  name: string.isRequired,
}

export default CoverageName
