import {
  apiService,
} from '@services/apiService'

interface InseeResponse {
  insee: string
}

export const insurerApi = apiService.injectEndpoints({
  endpoints: builder => ({
    getInseeCode: builder.query<InseeResponse, string>({
      query: formmatedAddress => `api/home/insee?formatted=${formmatedAddress}`,
    }),
  }),
})

export const {
  useLazyGetInseeCodeQuery,
} = insurerApi
