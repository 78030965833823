export enum EstimationStatus {
  COMPLETE = 'complete',
  FETCHING = 'fetching',
  CREATED = 'created',
}

export enum ResidenceType {
  Principal = 'principal',
  Secondary = 'secondary',
}

export interface Estimation {
  id: string;
  status: EstimationStatus;
  residenceType: string;
  occupationStatus: string;
  housingType: string;
  rooms: number;
  surface: number;
  capitalFurniture: number;
  accommodationCovered: boolean;
  contractTerminatedInLast3Years: boolean;
  userId: string;
  streetName: string;
  postalCode: string;
  locality: string;
  country: string;
  formattedAddress: string;
  streetNumber: number;
  additionalInformation: string;
  contractStartDate: Date;
  amountOfClaims: string;
  insee: string,
}
