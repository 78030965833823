import {
  Link,
} from 'react-router-dom'
import {
  Header as SelectraHeader,
} from '@selectra-it/selectra-ui'

import SelectraLogoMobile from '@assets/selectra-logo-mobile.svg?react'
import SelectraLogoDesktop from '@assets/selectra-logo-desktop.svg?react'
import useIsOfferPage from '@hooks/useIsOfferPage'
import Stepper from '@components/Wizard/Stepper'

import ContactButton from './ContactButton'

const Header = () => {
  const isOfferPage = useIsOfferPage()

  return (
    <SelectraHeader
      leftSlot={(
        <Link to={''}>
          <SelectraLogoMobile className="block md:hidden" />
          <SelectraLogoDesktop className="hidden md:block" />
        </Link>
      )}
      rightSlot={(
        <ContactButton />
      )}
      centerSlot={!isOfferPage && (
        <div className='container mx-auto hidden items-start md:flex'>
          <Stepper />
        </div>
      )}
    />
  )
}

export default Header
