import {
  Document,
} from '@root/domain/Model/Offer'

import DocumentListItem from './DocumentListItem'

interface DocumentListProps {
  documents: Document[]
}

const DocumentList = ({
  documents,
}: DocumentListProps) => {
  return (
    <div className='flex flex-col gap-2 md:gap-4'>
      <h3 className='text-left text-base font-bold md:text-lg'>Informations complèmentaires</h3>

      <div className='flex flex-col gap-2 text-sm md:gap-4 md:text-base'>
        {documents.map(document => {
          return <DocumentListItem key={document.id} document={document} />
        })}
      </div>
    </div>
  )
}

export default DocumentList
