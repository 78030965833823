import {
  useSearchParams,
} from 'react-router-dom'
import {
  useEffect,
} from 'react'
import {
  useDispatch,
} from 'react-redux'

import {
  setFormField,
} from '@root/services/formSlice'

export interface UtmCampaignForm {
  utm_source: string,
  utm_medium: string,
  utm_campaign: string,
}

export const usePersistUtmParameters = () => {
  const [params] = useSearchParams()
  const dispatch = useDispatch()

  const utm_source = params.get('utm_source')
  const utm_medium = params.get('utm_medium')
  const utm_campaign = params.get('utm_campaign')

  useEffect(() => {
    const utmParams = Object.fromEntries(
      Object.entries({
        utm_source, utm_medium, utm_campaign,
      }).filter(([, value]) => value !== null)
    )

    dispatch(setFormField(utmParams))
  }, [utm_source, utm_medium, utm_campaign, dispatch])

  return {
    source: utm_source,
    medium: utm_medium,
    campaign: utm_campaign,
  }
}
