import {
  useGetOffersByEstimationIdQuery,
} from '@root/services/offers'
import {
  selectSortingValues,
} from '@root/services/offers/offerSortingSlice'
import {
  useAppSelector,
} from '@root/store/Reducer'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useGetOffersByEstimationId = (estimationId: string, params?: any) => {
  const sorting = useAppSelector(state => selectSortingValues(state))
  const result = useGetOffersByEstimationIdQuery({
    estimationId,
    ...sorting,
  }, {
    skip: !estimationId,
    ...params,
  })

  return {
    ...result,
  }
}

export default useGetOffersByEstimationId
