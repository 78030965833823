import {
  matchPath, useLocation,
} from 'react-router-dom'

import {
  steps,
} from '@root/routes/wizard'
import {
  useAppSelector,
} from '@root/store/Reducer'
import {
  getFormState,
} from '@root/services/formSlice'
import {
  useGetFacilityTypesQuery,
} from '@root/services/facilities'

const useGetWizardRouteInformation = () => {
  const {
    pathname,
  } = useLocation()

  const facilityTypes = useGetFacilityTypesQuery()
  const state = useAppSelector(state => getFormState(state))

  const filteredSteps = steps.filter(step => step.shouldRender(state, {
    facilityTypes: facilityTypes.data,
  }))

  const currentStepIndex = filteredSteps.findIndex(step => matchPath(step.path, pathname)) + 1

  return {
    totalSteps: filteredSteps.length,
    currentStepIndex,
  }
}

export default useGetWizardRouteInformation
