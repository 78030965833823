import {
  Guarantee, Pack,
} from '@root/domain/Model/Offer'
import {
  useGetCoverageTypesQuery,
} from '@root/services/coverages'

const useCoverageName = (item: Pack | Guarantee | string) => {
  const {
    data,
  } = useGetCoverageTypesQuery()

  if (typeof item === 'string') {
    return item
  }

  const isPack = !('coverage' in item)

  const getPackName = (pack: Pack) => {
    const coverageIds = pack.coverages.map(coverage => coverage.id)

    const items = data?.filter(item => coverageIds.includes(item.id))

    return items?.map(item => item.shortLabel).join(' + ')
  }

  const getGuaranteeName = (guarantee: Guarantee) => {
    const item = data?.find(item => item.id === guarantee.coverage.id)

    return item?.label
  }

  return isPack ? getPackName(item) : getGuaranteeName(item)
}

export default useCoverageName
