import {
  FC, ReactNode,
} from 'react'
import {
  Link, useParams,
} from 'react-router-dom'

import Icon from '@components/Icon'

interface CallbackContainerProps {
  children: ReactNode
}

const CallbackContainer: FC<CallbackContainerProps> = ({
  children,
}) => {
  const {
    estimationId, offerId,
  } = useParams()

  return (
    <div className='flex flex-col gap-6'>
      <div>
        <Link to={`/offre/${estimationId}/${offerId}/mer`}>
          <Icon name='arrow back' size={24}
            className='min-w-min fill-neutral-300'
          />
        </Link>
      </div>
      {children}
    </div>
  )
}

export default CallbackContainer
