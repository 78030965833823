import {
  Modal,
} from '@selectra-it/selectra-ui'
import {
  useLocation, useNavigate,
  useParams,
} from 'react-router-dom'

import useGetOffersByEstimationId from '@hooks/offers/useGetOffersByEstimationId'
import {
  useAppSelector,
} from '@root/store/Reducer'
import {
  selectOfferById,
} from '@root/services/offers/selectors'

import RatingModalContent from './RatingModalContent'

const RatingModal = () => {
  const {
    offerId, estimationId,
  } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const {
    data,
  } = useGetOffersByEstimationId(estimationId as string)
  const offer = useAppSelector(state => selectOfferById(estimationId as string, offerId as string)(state))

  if (!offer || !data) {
    return null
  }

  const {
    rank, rating,
  } = offer
  const open = location.pathname.includes('rating')

  return (
    <>
      <Modal
        open={open}
        setOpen={() => {
          navigate(`/offre/${estimationId}`)
        }}
      >
        <RatingModalContent rank={rank} rating={rating}
          totalItems={data?.items.length}
        />
      </Modal>
    </>
  )
}

export default RatingModal
