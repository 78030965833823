import React from 'react'
import {
  FieldError, FieldValues, Path, UseFormRegister,
} from 'react-hook-form'
import {
  ErrorMessages, InputGroup, RADIO_STATUS, RadioBox, RadioBoxContainer, getErrorMessages,
} from '@selectra-it/selectra-ui'

const booleanItems = [
  {
    value: 'true',
    label: 'Oui',
  },
  {
    value: 'false',
    label: 'Non',
  }
]

interface PropsType<T extends FieldValues> {
  errors?: FieldError;
  register: UseFormRegister<T>,
  name: Path<T>,
  onAnimationFinished: () => void,
}

export const BooleanField: <T extends FieldValues>(p: PropsType<T>) => React.ReactElement = ({
  register,
  errors,
  name,
  onAnimationFinished,
}) => {
  return (
    <InputGroup errors={getErrorMessages(ErrorMessages, errors?.type)}>
      <RadioBoxContainer>
        {booleanItems.map(item => (
          <RadioBox key={item.value}
            animate={true}
            onAnimationFinished={onAnimationFinished}
            id={item.value}
            {...register(name, {
              required: true,
            })}
            item={{
              value: item.value,
              label: item.label,
            }}
            status={errors ? RADIO_STATUS.DANGER : RADIO_STATUS.DEFAULT}
          />
        ))}
      </RadioBoxContainer>
    </InputGroup>
  )
}

export default BooleanField
