import {
  useTranslation,
} from 'react-i18next'

import FacilitiesInput from '@components/form/FacilitiesInput'
import useEstimationWizardForm from '@hooks/estimation/useEstimationWizardForm'
import NavigationButtons from '@components/form/NavigationButtons'

import {
  WizardStepProps,
} from '../EstimationFormWizard'

const FacilitiesInformation = ({
  next,
}: WizardStepProps) => {
  const {
    form: {
      register, watch, formState: {
        errors,
      },
    },
    onSubmitHandler, onBackHandler,
  } = useEstimationWizardForm({
    mode: 'onChange',
    revalidateMode: 'onChange',
    next,
  })

  const {
    t,
  } = useTranslation()

  const facilities = watch('facilities')
  const label = t('next', {
    count: facilities?.length ?? 0,
  })

  return (
    <form onSubmit={onSubmitHandler()} className='flex w-full grow flex-col justify-between gap-12'>
      <FacilitiesInput
        register={register}
        name='facilities'
        errors={errors.facilities}
      />

      <NavigationButtons onBackHandler={onBackHandler} nextLabel={label} />
    </form>
  )
}

export default FacilitiesInformation
