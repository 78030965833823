import {
  FC,
} from 'react'
import {
  FieldError, UseFormRegister,
} from 'react-hook-form'
import {
  ErrorMessages, InputGroup, RADIO_STATUS, RadioStacked, RadioStackedContainer, getErrorMessages,
} from '@selectra-it/selectra-ui'

import {
  useGetFamilitySitutationTypesQuery,
} from '@root/services/familySituation'
import {
  EstimationForm,
} from '@root/domain/Model/Wizard'

interface FamilySituationProps {
  register: UseFormRegister<EstimationForm>,
  errors?: FieldError,
  onAnimationFinished: () => void,
}

const FamilySituation: FC<FamilySituationProps> = ({
  register,
  errors,
  onAnimationFinished,
}) => {
  const {
    data, isLoading, error,
  } = useGetFamilitySitutationTypesQuery()

  if (isLoading || error || !data?.length) {
    return (
      <div />
    )
  }

  return (
    <InputGroup errors={getErrorMessages(ErrorMessages, errors?.type)}>
      <RadioStackedContainer>
        {data.map(item => {
          return (
            <RadioStacked key={item.value} id={item.value}
              item={item}
              animate={true}
              onAnimationFinished={onAnimationFinished}
              {...register('familySituation', {
                required: true,
              })}
              status={errors ? RADIO_STATUS.DANGER : RADIO_STATUS.DEFAULT}
            />
          )
        })}
      </RadioStackedContainer>
    </InputGroup>
  )
}

export default FamilySituation
