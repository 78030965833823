import {
  Routes, Route, BrowserRouter,
  Outlet,
  createBrowserRouter,
} from 'react-router-dom'

import App from '@root/App'
import OffersPage from '@root/pages/OffersPage'
import EstimationFormWizard from '@components/form/EstimationForm/EstimationFormWizard'
import Mer from '@components/offers/Subscription/Mer'
import RequestCallbackSelectraAction from '@components/offers/Subscription/v2/actions/RequestCallbackSelectraAction'
import RequestCallbackAction from '@components/offers/Subscription/v2/actions/RequestCallbackAction'
import CallbackSuccess from '@components/offers/Subscription/v2/CallbackSuccess'
import SubscriptionSuccess from '@components/offers/Subscription/v2/SubscriptionSuccess'
import CallbackContainer from '@components/offers/Subscription/v2/CallbackContainer'
import Redirect from '@root/pages/Redirect'

const CustomRoutes = () => (
  <BrowserRouter>
    <Routes>
      <Route path='/*' element={<App />} />
    </Routes>
  </BrowserRouter>
)

export const router = createBrowserRouter([
  {
    path: '/*',
    element: <App />,
    children: [
      {
        path: 'offre/:estimationId/*?',
        element: <OffersPage />,
        children: [
          {
            path: ':offerId',
            element: <Outlet />,
            children: [
              {
                path: 'rating',
                element: <Outlet />,
              },
              {
                path: 'mer',
                element: <Outlet />,
                children: [
                  {
                    path: '',
                    element: <Mer />,
                  },
                  {
                    path: 'subscription/:merId',
                    element: <SubscriptionSuccess />,
                  },
                  {
                    path: 'callback',
                    element: <CallbackContainer>
                      <RequestCallbackAction />
                    </CallbackContainer>,
                  },
                  {
                    path: 'callback/:merId',
                    element: <CallbackSuccess />,
                  },
                  {
                    path: 'callback-selectra',
                    element: <CallbackContainer>
                      <RequestCallbackSelectraAction />
                    </CallbackContainer>,
                  },
                  {
                    path: 'callback-selectra/:merId',
                    element: <CallbackSuccess />,
                  }
                ],
              }
            ],
          }
        ],
      },
      {
        path: '*',
        element: <EstimationFormWizard />,
      },
      {
        path: 'comparer/*',
        element: <Redirect />,
      }
    ],
  }
])

export default CustomRoutes
