import {
  ReactNode,
} from 'react'

interface CollapsibleMenuProps {
  trigger: ReactNode,
  children: ReactNode,
}

const CollapsibleMenu = ({
  trigger,
  children,
}: CollapsibleMenuProps) => {
  return (
    <li className='relative mb-6 rounded-lg border border-neutral-300 p-4'>
      {trigger}

      <div className="max-h-0 overflow-hidden transition-all duration-500 peer-checked:max-h-[580px]">
        {children}
      </div>
    </li>
  )
}

export default CollapsibleMenu
