import {
  apiService,
} from '@services/apiService'

interface ValidatePhoneNumberQuery {
  number: string
  country: string
}

export const PhoneNumberApi = apiService.injectEndpoints({
  endpoints: builder => ({
    validatePhoneNumber: builder.query<null, ValidatePhoneNumberQuery>({
      query: ({
        number, country,
      }) => ({
        url: 'api/phone',
        params: {
          phone: {
            number,
            country,
          },
        },
      }),
    }),
  }),
})

export const {
  useValidatePhoneNumberQuery,
  useLazyValidatePhoneNumberQuery,
} = PhoneNumberApi
