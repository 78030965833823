import Icon from '@components/Icon'
import useCoverageName from '@hooks/useCoverageName'
import {
  Guarantee, Pack,
} from '@root/domain/Model/Offer'

interface GuaranteeListItemProps {
  item: Pack | Guarantee | string,
  onClick?: () => void
}

const GuaranteeListItem = ({
  item,
  onClick,
}: GuaranteeListItemProps) => {
  const label = useCoverageName(item)

  const textStyles = onClick ? 'text-primary-400 underline' : 'text-neutral-400'

  return (
    <div className={`flex items-center gap-2 ${onClick ? 'cursor-pointer' : ''} text-sm md:text-base`} onClick={onClick}>
      <Icon name='check' size={24}
        className='fill-success-300'
      />
      <h5 className={textStyles}>{label}</h5>
    </div>

  )
}

export default GuaranteeListItem
