import {
  func,
} from 'prop-types'

import image from '@assets/agents/picture-2.png'
import FranceIcon from '@assets/flag/france.svg?react'
import Header from '@components/Modal/Header'
import {
  readablePhoneNumber,
} from '@root/util/formatting'
import {
  PhoneNumbers,
} from '@root/domain/Crm/PhoneNumbers'
import {
  LeadsourceOption,
} from '@root/domain/Crm/LeadsourceOption'

import {
  Step,
} from './Step'

const ContactInformation = ({
  dispatch,
}) => {
  const phoneNumber = PhoneNumbers[LeadsourceOption.COMPARATOR_HELP]

  return (
    <div className='grid grid-cols-1 gap-3'>
      <Header title='Vous avez besoin d’aide ?'
        icon='help'
      />
      <h4 className='text-sm text-neutral-400'>Nos conseillers sont disponibles pour répondre à vos questions par téléphone.</h4>

      <div className='grid grid-cols-1 gap-3 rounded-lg border-2 border-neutral-150 p-4 text-center text-neutral-400 shadow-lg'>
        <div className='flex'>
          <img className="inline-block size-8 rounded-full" src={image}
            alt=""
          />
          <a className='grow text-2xl font-bold text-primary-400' href={`tel:+33${phoneNumber}`}>{readablePhoneNumber(phoneNumber)}</a>
          <FranceIcon />
        </div>
        <span className='text-xs'>ou&nbsp;
          <a className='text-blue-600 underline visited:text-purple-600 hover:text-blue-800'
            onClick={() => {
              dispatch({
                type: Step.CALLBACK,
              })
            }}
          >faites-vous rappeler gratuitment </a>
        </span>
      </div>
    </div>
  )
}

ContactInformation.propTypes = {
  dispatch: func.isRequired,
  closeModal: func.isRequired,
}

export default ContactInformation
